<template>
  <v-row justify="center">
    <v-form ref="autorizadosForm" v-model="valid" lazy-validation>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="center">
          <v-data-table
            :headers="headers"
            :items="autorizados"
            class="elevation-0"
            :hide-default-footer="true"
            :key="keyTableAutorizados"
            item-key="index"
            style="width: 100%"
          >
            <template v-slot:[`item.tipo_documento_id`]="{ item }">
              <v-autocomplete
                :items="identifiertypes"
                item-value="id"
                item-text="name"
                :label="item.tipo_documento_id ? '' : 'Tipo Doc.'"
                v-model="item.tipo_documento_id"
                :rules="[$rulesRequerido]"
                return-object
              ></v-autocomplete>
            </template>
            <template v-slot:[`item.documento`]="{ item }">
              <v-text-field
                v-if="!isCuilCuit(item.tipo_documento_id)"
                v-model="item.documento"
                :label="item.documento ? '' : item.tipo_documento_id.name"
                :rules="[$rulesRequerido, $rulesDni]"
                maxLength="8"
                required
              ></v-text-field>
              <v-text-field
                v-else
                v-model="item.documento"
                :label="item.documento ? '' : item.tipo_documento_id.name"
                :rules="[$rulesRequerido, $rulesCuit]"
                hint="Ingrese solo números"
                maxLength="13"
                v-mask="'##-########-#'"
              ></v-text-field>
            </template>
            <template v-slot:[`item.apellidos`]="{ item }">
              <v-text-field
                v-model="item.apellidos"
                :label="item.apellidos ? '' : 'Apellidos'"
                :rules="[$rulesRequerido, $rulesAlfaNum]"
                maxLength="50"
              ></v-text-field>
            </template>
            <template v-slot:[`item.nombres`]="{ item }">
              <v-text-field
                v-model="item.nombres"
                :label="item.nombres ? '' : 'Nombres'"
                :rules="[$rulesRequerido, $rulesAlfaNum]"
                maxLength="50"
              ></v-text-field>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-col cols="auto pa-0">
                <v-btn
                  :color="$cv('btnEditar')"
                  fab
                  x-small
                  dark
                  title="Reset"
                  @click="resetRow(item)"
                  class="x-btn-grid"
                >
                  <v-icon>mdi-file-restore-outline</v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
    </v-form>
  </v-row>
</template>

<script>
export default {
  props: {
    items: { Number, default: 0 },
    datosAutorizados: { Array, default: null },
  },
  data: (vm) => ({
    keyTableAutorizados: 0,
    headers: [
      /*{ text: "Numero", filterable: true, value: "numero" },*/
      { text: "Id", value: "index", align: " d-none" },
      { text: "Tipo Doc.", value: "tipo_documento_id" },
      { text: "Documento", value: "documento" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Nombres", value: "nombres" },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
        class: "acciones-width",
      },
    ],
    avatar: "/img/user_primary.png",
    autorizados: [],
    identifiertypes: [],
    valid: true,
  }),
  methods: {
    isCuilCuit(item) {
      if (item) {
        return (
          item.name.toUpperCase() == "CUIT" || item.name.toUpperCase() == "CUIL"
        );
      }
    },
    saveApoderado(value) {
      //console.log("guardando data d personas");
      if (value) {
        let i = Number(value.index);
        this.autorizados[i] = value;
        //	console.log(this.autorizados);
        this.keyTableAutorizados = this.keyTableAutorizados + 1;
      }
    },
    add(items) {
      let numero = 0;

      for (let i = 0; i < Number(items); i++) {
        if (i == 0) {
          numero = 1;
        } else {
          numero++;
        }

        this.autorizados.push({
          id: "",
          apellidos: "",
          nombres: "",
          tipo_documento_id: "",
          documento: "",
          numero: numero,
          index: Number(i),
        });
        if (this.datosAutorizados) {
          if (typeof this.datosAutorizados !== "undefined") {
            if (typeof this.datosAutorizados[i] !== "undefined") {
              this.autorizados[i] = this.datosAutorizados[i];
            }
          }
        }
      }
      //console.log(this.autorizados);
    },
    remove(index) {
      this.autorizados.splice(index, 1);
    },
    resetRow(item) {
      console.log(item);
      let index = item.index;
      this.autorizados[index].apellidos = "";
      this.autorizados[index].nombres = "";
      this.autorizados[index].tipo_documento_id = "";
      this.autorizados[index].documento = "";
    },
  },
  created() {
    this.$getExternalListForSelect("identifiertypes");
    if (this.items) {
      this.add(this.items);
      //this.autorizados = this.datosAutorizados;
    }
  },
  mounted() {
    console.log("Componente AutorizadosRow creado");
    if (this.items > 0) {
      // this.autorizados = this.datosAutorizados;
    }
  },
};
</script>

<style scoped>
.text-fields-row {
  display: flex;
}
.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 0px;
}
.back-condomino {
  background-color: whitesmoke;
}
.icon-center {
  margin: 4px 0px 0px 3px;
}
.icon-size {
  width: 32px !important;
  height: 32px !important;
}
</style>
