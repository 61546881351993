<template>
  <v-row>
    <v-col cols="12" md="12" sm="12">
      <v-row justify="center">
        <v-data-table
          :headers="headers"
          :items="apoderados"
          class="elevation-0"
          :hide-default-footer="true"
          :key="keyTableApoderados"
          item-key="index"
          style="width: 100%"
        >
          <template v-slot:[`item.numero`]="{ item }">
            <table class="px-0 mx-0">
              <tr>
                <td class="pa-0 mx-0">
                  <v-img
                    class="icon-size"
                    :src="
                      (editedItem.index + 1) % 2 != 0 ? avatar : avatarCondomino
                    "
                  ></v-img>
                </td>
                <td class="pa-0 mx-0 text-start">
                  <v-chip
                    prepend-icon="mdi-user"
                    class="ml-1 d-flex justify-center"
                    default
                    style="width: 50px"
                    :color="
                      (editedItem.index + 1) % 2 != 0 ? 'primary' : 'primary-2'
                    "
                  >
                    {{ item.funcion }}
                  </v-chip>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.firstName`]="{ item }">
            {{
              item.lastName
                ? item.lastName + ", " + item.firstName
                : item.firstName
            }}
          </template>

          <template v-slot:[`item.cuit`]="{ item }"> {{ item.cuit }} </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-col cols="auto">
              <v-btn
                :color="
                  (editedItem.index + 1) % 2 != 0 ? 'primary' : 'primary-2'
                "
                fab
                small
                @click="showApoderadoForm(item)"
              >
                <v-icon
                  color="white"
                  title="Cargar o editar datos del apoderado"
                  >mdi-account-edit</v-icon
                >
              </v-btn>

              <v-btn
                :color="'danger'"
                fab
                small
                @click="resetRow(item.index)"
                v-if="item.id"
              >
                <v-icon color="white" title="Limpiar datos del Apoderado"
                  >mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
    <v-dialog v-model="dialogApoderado" persistent width="80%">
      <ApoderadoForm
        @send-message="close()"
        @save-persona="saveApoderado"
        :title="title"
        :item="apoderado"
        :key="keyFormApoderado"
        :items="apoderados"
        :tipoPersona="3"
      ></ApoderadoForm>
    </v-dialog>
  </v-row>
</template>

<script>
import ApoderadoForm from "../../personas/components/ApoderadoForm";
export default {
  components: {
    ApoderadoForm,
  },
  props: {
    items: { Number, default: 0 },
    records: { Array, default: null },
    editedItem: { Object, default: null },
  },
  data: (vm) => ({
    form: {},
    modal: false,
    dialogApoderado: false,
    title: "",
    apoderado: {},
    keyTableApoderados: 0,
    keyFormApoderado: 0,
    headers: [
      /*{ text: "Numero", filterable: true, value: "numero" },*/
      { text: "Id", value: "index", align: " d-none" },
      { text: "Apoderado", value: "numero" },
      { text: "Cuit / Cuil", value: "cuit" },
      { text: "Nombre", value: "firstName" },

      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
        class: "acciones-width",
      },
    ],

    avatar: "/img/user_primary.png",
    avatarCondomino: "/img/user_primary_2.png",
    apoderados: [],
    itemKey: "",
    baseUrl: "",
  }),
  methods: {
    saveApoderado(value) {
      //console.log("guardando data d personas");
      if (value) {
        let i = Number(value.index);
        this.apoderados[i] = value;
        //	console.log(this.apoderados);
        this.keyTableApoderados = this.keyTableApoderados + 1;
      }
    },
    close() {
      this.dialogApoderado = false;
    },
    showApoderadoForm(item) {
      this.title = "Apoderado " + item.numero;
      this.apoderado = item;
      this.keyFormApoderado = this.keyFormApoderado + 1;
      this.dialogApoderado = true;
    },
    add(items) {
      let numero = 0;
      let funcion = "";
      for (let i = 0; i < Number(items); i++) {
        if (i == 0) {
          numero = 1;
        } else {
          numero++;
        }
        funcion = "N° " + numero;

        this.apoderados.push({
          id: "",
          tipo_persona: {},
          tipo_documento: "",
          dni: "",
          lastName: "",
          firstName: "",
          cuit: "",
          pais_dni: "",
          nacionalidad: "",
          ocupacion: "",
          telefono: "",
          mail: "",
          fecha_nacimiento: "",
          lugar_nacimiento: "",
          estado_civil: "",
          formulario_01: "",
          tipo_documento: "",
          documento: "",
          sexo: "",
          actividad: "",
          otorgada_por: "",
          titular_principal: false,
          numero_inscripcion: "",
          fecha_inscripcion: "",
          apoderados: "",
          domicilioLegal: {},
          domicilioReal: {},
          numero: numero,
          index: Number(i),
          indexPadre: this.editedItem.index,

          funcion: funcion,
        });
        if (this.records) {
          if (typeof this.records !== "undefined") {
            if (typeof this.records[i] !== "undefined") {
              this.apoderados[i] = this.records[i];
            }
          }
        }
      }
      //console.log(this.apoderados);
    },
    remove(index) {
      this.apoderados.splice(index, 1);
    },
    resetRow(index) {
      this.apoderados[index].lastName = "";
      this.apoderados[index].firstName = "";
      this.apoderados[index].telefono = "";
    },

    resetRow(index) {
      Swal.fire(
        Swal.objQuestion(
          "<b>Borrar Datos de Persona</b>",
          "¿Desea borrar los datos de la persona?"
        )
      ).then((result) => {
        if (result.isConfirmed && this.apoderados[index]) {
          //Limpio los datos de la persona
          this.apoderados[index].id = "";
          this.apoderados[index].tipo_documento = "";
          this.apoderados[index].dni = "";
          this.apoderados[index].activity = "";
          this.apoderados[index].apellido = "";
          this.apoderados[index].nombre = "";
          this.apoderados[index].lastName = "";
          this.apoderados[index].firstName = "";
          this.apoderados[index].businessName = "";
          this.apoderados[index].birthPlace = "";
          this.apoderados[index].birthDate = "";
          this.apoderados[index].cuit = "";
          this.apoderados[index].pais_dni = "";
          this.apoderados[index].nacionalidad = "";
          this.apoderados[index].ocupacion = "";
          this.apoderados[index].telefono = "";
          this.apoderados[index].mail = "";
          this.apoderados[index].fecha_nacimiento = "";
          this.apoderados[index].lugar_nacimiento = "";
          this.apoderados[index].estado_civil = "";
          this.apoderados[index].formulario_01 = "";
          this.apoderados[index].tipo_documento = "";
          this.apoderados[index].documento = "";
          this.apoderados[index].sexo = "";
          this.apoderados[index].actividad = "";
          this.apoderados[index].otorgada_por = "";
          this.apoderados[index].numero_inscripcion = "";
          this.apoderados[index].fecha_inscripcion = "";
          this.apoderados[index].apoderados = "";
          this.apoderados[index].porcentaje = 0;
          this.apoderados[index].titular_principal = false;
          this.apoderados[index].personType = {};
          this.apoderados[index].meritalStatusId = {};
          this.apoderados[index].genderId = {};
          this.apoderados[index].domicilioLegal = {};
          this.apoderados[index].domicilioReal = {};
          this.apoderados[index].tipo_persona = "";

          Swal.close();
          Swal.fire(
            Swal.objSuccess(
              "Campos vaciados",
              "Se han vaciado los campos de la persona"
            )
          );
        }
      });
    },
  },
  created() {
    if (this.items) {
      this.add(this.items);
      //this.autorizados = this.datosAutorizados;
    }
  },
  mounted() {
    console.log("Componente ApoderadosRow creado");
  },
};
</script>

<style scoped>
.text-fields-row {
  display: flex;
}
.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 0px;
}
.back-condomino {
  background-color: whitesmoke;
}
.icon-center {
  margin: 4px 0px 0px 3px;
}
.icon-size {
  width: 32px !important;
  height: 32px !important;
}
</style>
