<template>
  <v-card>
    <v-form ref="personaForm" v-model="valid" lazy-validation>
      <v-toolbar
        dark
        :color="
          Number(editedItem.indexPadre) % 2 == 0 ? 'primary' : 'primary-2'
        "
      >
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="save()">Guardar</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="card-scrolleable" style="max-height: 81vh">
        <v-container>
          <v-row justify="center">
            <v-col
              cols="12"
              md="1"
              sm="12"
              style="margin-right: -14px"
              class="pa-0"
            >
              <v-img
                class="icon-size"
                :src="
                  (editedItem.indexPadre + 1) % 2 != 0
                    ? avatar
                    : avatarCondomino
                "
              ></v-img>
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="12"
              style="border-radius: 10px"
              class="pa-0"
            >
              <div
                :class="
                  (editedItem.indexPadre + 1) % 2 != 0 ? 'primary' : 'primary-2'
                "
                class="py-7 rounded-pill ml-0 mr-1 text-center text-white"
                default
                :color="
                  (editedItem.indexPadre + 1) % 2 != 0 ? 'primary' : 'primary-2'
                "
              >
                {{ editedItem.funcion }}
              </div>
            </v-col>
            <v-col cols="12" md="9" sm="12" class="pl-0">
              <v-row
                justify="center"
                class="rounded-pill ml-0 mr-9"
                :style="{
                  backgroundColor:
                    (editedItem.indexPadre + 1) % 2 != 0
                      ? $cv('primary') + '20'
                      : $cv('color') + '20',
                }"
              >
                <v-col
                  cols="12"
                  md="4"
                  sm="12"
                  class="pa-0 pl-4"
                  v-if="tipoPersona != 3"
                >
                  <v-text-field
                    v-model="editedItem.formulario_01"
                    label="Formulario 01"
                    class="input-h1"
                    :rules="[$rulesRequerido, $rulesFormularios]"
                    maxLength="8"
                    :disabled="Number(editedItem.index) % 2 != 0"
                    :title="
                      Number(editedItem.indexPadre) % 2 != 0
                        ? 'El condomino posee el mismo numero de formulario que el titular'
                        : ''
                    "
                    prepend-inner-icon="mdi-file-document-check"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="pa-0 pl-4">
                  <v-autocomplete
                    :items="tipoPersonas"
                    item-value="id"
                    item-text="name"
                    label="Tipo de Persona"
                    v-model="editedItem.personType"
                    @change="showTipoPersona()"
                    :rules="[$rulesRequerido]"
                    return-object
                    class="input-h1"
                    prepend-inner-icon="mdi-badge-account-outline"
                    :readonly="tipoPersona == 3"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" md="4" sm="12" class="pa-0 pl-4">
                  <v-autocomplete
                    :items="tiposCuitCuil"
                    item-value="id"
                    item-text="name"
                    label="Cuil/Cuit"
                    v-model="editedItem.tipo_cuitcuil"
                    :rules="[$rulesRequerido]"
                    return-object
                    class="input-h1"
                    prepend-inner-icon="mdi-badge-account-outline"
                    @change="
                      $verifyIdentifiers(
                        editedItem.tipo_cuitcuil,
                        editedItem.cuit
                      )
                    "
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" md="4" sm="12" class="pa-0 pl-4">
                  <v-text-field
                    v-model="editedItem.cuit"
                    label="CUIT/CUIL"
                    :rules="[$rulesRequerido, $rulesCuit]"
                    hint="Ingrese solo números"
                    maxLength="13"
                    class="input-h1"
                    v-mask="'##-########-#'"
                    @blur="
                      $verifyIdentifiers(
                        editedItem.tipo_cuitcuil,
                        editedItem.cuit
                      );
                      setDNI();
                    "
                    prepend-inner-icon="mdi-card-account-details"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--
          <v-row justify="center">
            <v-col cols="12" sm="9" class="pa-0 pl-4">
              <v-divider  class="mt-4 pa-2 " :class="Number(editedItem.indexPadre) % 2 == 0 ? 'border-primary' : 'border-primary-2'"
          ></v-divider>
            </v-col>
          </v-row>
-->
          <template v-if="personaFisica">
            <v-row justify="center">
              <v-col cols="12" sm="12" class="pa-0 pl-4 pr-4">
                <v-divider
                  class="mt-2 pa-2"
                  :class="
                    Number(editedItem.indexPadre) % 2 == 0
                      ? 'border-primary'
                      : 'border-primary-2'
                  "
                ></v-divider>
              </v-col>
            </v-row>
            <v-row
              :style="{
                borderRadius: '10px',
                margin: '4px',
                backgroundColor:
                  (item.index + 1) % 2 != 0
                    ? $cv('primary') + '20'
                    : $cv('color') + '20',
              }"
            >
              <v-col>
                <v-row>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-autocomplete
                      :items="tiposDocumentos"
                      item-value="id"
                      item-text="name"
                      label="Tipo Documento"
                      v-model="editedItem.tipo_documento"
                      :rules="[$rulesRequerido]"
                      return-object
                      class="input-h1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.documento"
                      label="Documento"
                      :rules="[$rulesRequerido, $rulesDni]"
                      hint="Ingrese solo números"
                      class="input-h1"
                      maxLength="8"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.lastName"
                      label="Apellido"
                      :rules="[$rulesRequerido, $rulesAlfaNum]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.firstName"
                      label="Nombre"
                      :rules="[$rulesRequerido, $rulesAlfaNum]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="12"
                    class="py-0"
                    v-if="tipoPersona != 3"
                  >
                    <v-text-field
                      v-model="editedItem.porcentaje"
                      label="Porcentaje de Titularidad"
                      :rules="[$rulesRequerido, $rulesPorcentaje]"
                      hint="Ingrese un valor mayor a 0 y menor o igual que 100"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.birthDate"
                          label="Fecha de Nacimiento"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="date = parseDate(editedItem.birthDate)"
                          v-on="on"
                          v-on:focus="dateValido"
                          :rules="[
                            $rulesRequerido,
                            $rulesFechaValida,
                            $rulesFechaMenorAHoy,
                          ]"
                          v-mask="'##/##/####'"
                          placeholder="dd/mm/yyyy"
                          maxLength="10"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        class="ma-0"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.birthPlace"
                      label="Lugar de Nacimiento"
                      :rules="[$rulesRequerido]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-autocomplete
                      :items="genders"
                      item-value="id"
                      item-text="name"
                      label="Sexo"
                      v-model="editedItem.genderId"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-autocomplete
                      :items="maritalstatuses"
                      item-value="id"
                      item-text="name"
                      label="Estado Civil"
                      v-model="editedItem.maritalStatusId"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-autocomplete
                      :items="paises"
                      item-value="id"
                      item-text="name"
                      label="Autoridad que expidió el documento"
                      v-model="editedItem.pais_dni"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-autocomplete
                      :items="paises"
                      item-value="id"
                      item-text="name"
                      label="Nacionalidad"
                      v-model="editedItem.nacionalidad"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.activity"
                      label="Ocupación"
                      :rules="[$rulesRequerido]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.mail"
                      label="Email"
                      :rules="[$rulesRequerido, $rulesMail]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.telefono"
                      label="Telefono"
                      :rules="[$rulesRequerido, $rulesTelefono]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" md="5" sm="12" class="py-0">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Razon Social"
                  :rules="[$rulesRequerido, $rulesAlfaNum]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="5" sm="12" class="py-0">
                <v-text-field
                  v-model="editedItem.actividad"
                  label="Actividad"
                  :rules="[$rulesRequerido, $rulesAlfaNum]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" sm="12" class="py-0">
                <v-text-field
                  v-model="editedItem.porcentaje"
                  label="Porcentaje de Titularidad"
                  :rules="[$rulesRequerido, $rulesPorcentaje]"
                  hint="Ingrese un valor mayor a 0 y menor o igual que 100"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" sm="12" class="py-0">
                <v-autocomplete
                  :items="paises"
                  item-value="id"
                  item-text="name"
                  label="Autoridad que lo expidió"
                  v-model="editedItem.pais_dni"
                  :rules="[$rulesRequerido]"
                  return-object
                ></v-autocomplete
              ></v-col>
              <v-col cols="12" md="3" sm="12" class="py-0">
                <v-autocomplete
                  :items="entidades"
                  item-value="id"
                  item-text="name"
                  label="Personeria Otorgada por"
                  v-model="editedItem.otorgada_por"
                  :rules="[$rulesRequerido]"
                  return-object
                ></v-autocomplete
              ></v-col>
              <v-col cols="12" md="3" sm="12" class="py-0">
                <v-text-field
                  v-model="editedItem.numero_inscripcion"
                  label="Numero de Inscripción"
                  :rules="[$rulesRequerido, $rulesAlfaNum]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="12" class="py-0">
                <v-text-field
                  v-model="editedItem.fecha_inscripcion"
                  label="Fecha de Inscripción o Creación"
                  :rules="[
                    $rulesRequerido,
                    $rulesFechaValida,
                    $rulesFechaMenorAHoy,
                  ]"
                  v-mask="'##/##/####'"
                  placeholder="dd/mm/yyyy"
                  maxLength="10"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5" sm="12" class="py-0">
                <v-text-field
                  v-model="editedItem.mail"
                  label="Email"
                  :rules="[$rulesRequerido, $rulesMail]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" sm="12" class="py-0">
                <v-text-field
                  v-model="editedItem.telefono"
                  label="Telefono"
                  :rules="[$rulesRequerido, $rulesTelefono]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" sm="12" class="py-0">
                <!--<v-text-field
                  v-model="editedItem.apoderados"
                  label="Cantidad de apoderados"
                  :rules="[$rulesRequerido, $rulesEnterosPositivos]"
                  @change="setApoderados()"
                  maxLength="3"
                ></v-text-field>-->
                <v-text-field
                  v-model="editedItem.apoderados"
                  label="Cantidad de apoderados"
                  :rules="[$rulesRequerido, $rulesEnterosPositivos]"
                  @keyup="setApoderados()"
                  maxLength="3"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="py-0">
                    <v-card id="apoderados" outlined v-show="showApoderados">
                      <v-card-title
                        :class="
                          (editedItem.indexPadre + 1) % 2 != 0
                            ? 'primary text-white'
                            : 'primary-2  text-white'
                        "
                        class="text-h3 text-left"
                      >
                        <v-row wrap>
                          <v-col cols="12" :xs="12"> Apoderados </v-col>
                        </v-row>
                      </v-card-title>
                    </v-card>
                  </v-col></v-row
                ></v-col
              ></v-row
            >
          </template>

          <v-row class="pt-3">
            <v-col cols="12" md="6" sm="12" class="py-0">
              <DomicilioForm
                ref="domicilioLegal"
                :item="editedItem.domicilioLegal"
                title="Domicilio Legal"
                :key="keyDomicilioLegal"
                :index="editedItem.index"
                :indexPadre="editedItem.indexPadre"
              ></DomicilioForm>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <DomicilioForm
                ref="domicilioReal"
                :item="editedItem.domicilioReal"
                title="Domicilio Real"
                :showCopy="true"
                :key="keyDomicilioReal"
                @copiar-domicilio="copyDomicilio"
                :index="editedItem.index"
                :indexPadre="editedItem.indexPadre"
              ></DomicilioForm>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-card>
</template>

<script>
function getTitle() {
  return "Apoderado";
}
import DomicilioForm from "../../personas/components/DomicilioForm";

export default {
  name: "PersonaForm",
  components: {
    DomicilioForm,
  },
  props: {
    title: { String, default: null },
    item: { Object, default: null },
    items: { Object, default: null },
    tipoPersona: { Number, default: 1 },
  },
  data: (vm) => ({
    showApoderados: false,
    componentApoderados: 0,
    keyDomicilioLegal: 0,
    keyDomicilioReal: 1,
    personaFisica: true,
    valid: true,
    route: "personsAggregator",
    routePersons: "persons",
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    editedIndex: -1,
    editedItem: {
      domicilioLegal: {},
      domicilioReal: {},
      birthDate: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
    },
    formDomicilioLegal: {},
    formDomicilioReal: {},
    defaultItem: { domicilioLegal: {}, domicilioReal: {} },
    genders: [],
    identifiertypes: [],
    tiposCuitCuil: [],
    tiposDocumentos: [],
    maritalstatuses: [],
    apoderados: [],
    /*tipoPersonas: [
      { id: 1, name: "PERSONA FÍSICA" },
      { id: 2, name: "PERSONA JURÍDICA" },
    ],*/
    tipoPersonas: [],
    entidades: [
      { id: 1, name: "AGIP" },
      { id: 2, name: "IGJ" },
      { id: 3, name: "REGISTRO PUBLICO DE COMERCIO" },
    ],
    maritalstatuses: [
      { id: 1, name: "SOLTERO/A" },
      { id: 2, name: "CASADO/A" },
      { id: 3, name: "VIUDO/A" },
      { id: 4, name: "DIVORCIADO/A" },
    ],
    paises: [],
    avatar: "/img/user_primary.png",
    avatarCondomino: "/img/user_primary_2.png",
    formPerson: { identifiers: [], addresses: [] },
    tDocAnt: "",
    docAnt: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
  }),

  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (date) {
        return date;
      },
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.title
          ? "Registrar datos de " + this.title
          : "Registrar datos de " + getTitle()
        : this.title
        ? "Editar datos de " + this.title
        : "Editar datos de " + getTitle();
    },
    computedFechaNacimiento: {
      get: function () {
        return this.$formatDate(this.editedItem.birthDate);
      },
      set: function (date) {
        return date;
      },
    },
  },

  created() {
    //this.editedItem = this.item;
    this.tipoPersonas = this.$getPersonsTypes();
    this.editedItem = Object.assign({}, this.item);

    this.showTipoPersona();

    if (this.tipoPersona == 3) {
      this.editedItem.personType = 1;
      this.personaFisica = true;
    }
    //this.domicilioLegal = +1;
    //this.domicilioReal = +1;
    this.$getListPaisesForSelect("paises");
    this.$getExternalListForSelect("identifiertypes");
    this.$getExternalListForSelect("genders");
    //this.$getExternalListForSelect("maritalstatuses");
    //console.log(this.maritalstatuses);

    if (this.tipoPersona == 3) {
      //this.editedItem.tipo_persona.id = "1";
      this.personaFisica = true;
    }

    if (this.editedItem.id) {
      this.tDocAnt = this.editedItem.tipo_cuitcuil.id;
      this.docAnt = this.editedItem.cuit;
    }

    //this.setDatosPrueba();
  },

  methods: {
    setDatosPrueba() {
      this.editedItem.formulario_01 = "12345678";
      this.editedItem.personType = { id: 1, name: "FÍSICA" };
      this.editedItem.businessName = "ABERTURAS DEL SUR";
      this.editedItem.cuit = "20308762231";
      this.setDNI();
      this.editedItem.lastName = "APELLIDO REPRE LEGAL";
      this.editedItem.firstName = "NOMBRE REPRE LEGAL";
      this.editedItem.porcentaje = "100";
      this.editedItem.birthDate = "12/09/19783";
      this.editedItem.birthPlace = "ADROGUE";
      this.editedItem.genderId = { id: 1, name: "Masculino" };
      this.editedItem.maritalStatusId = { id: 2, name: "SOLTERO/A" };
      this.editedItem.pais_dni = { id: "ARGENTINA" };
      this.editedItem.nacionalidad = { id: "ARGENTINA" };
      this.editedItem.activity = "EMPLEADO INDUSTRIAL";
      this.editedItem.mail = "mail@mail.com";
      this.editedItem.telefono = "1111111111";
    },
    setDNI() {
      this.editedItem.documento = this.editedItem.cuit.substr(3, 8);
      let tipoDoc = this.identifiertypes.filter(
        (item) => item.name.toUpperCase() == "DNI"
      );
      //console.log(tipoDoc[0]);
      this.editedItem.tipo_documento = tipoDoc[0]
        ? tipoDoc[0]
        : { id: 4, name: "DNI" };
    },
    setApoderados() {
      this.componentApoderados = this.componentApoderados + 1;
      this.showApoderados = true;
      this.apoderados = this.editedItem.apoderados;
    },
    showTipoPersona() {
      if (this.editedItem.personType) {
        this.personaFisica = this.editedItem.personType == 1 ? true : false;
      }
    },
    copyDomicilio(v) {
      if (v == true) {
        this.editedItem.domicilioReal = this.editedItem.domicilioLegal;
      } else {
        this.editedItem.domicilioReal = {};
      }
    },
    reset() {
      //this.$refs.personaForm.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation();
    },
    close() {
      this.reset();
      this.$emit("send-message");
    },
    resetValidation() {
      this.$refs.personaForm.resetValidation();
    },

    save() {
      if (this.$refs.personaForm.validate()) {
        this.formPerson.personType = 1;
        this.formPerson.addresses = [];
        this.formPerson.identifiers = [];
        this.formPerson.businessName = this.editedItem.businessName
          ? this.editedItem.businessName
          : "";
        this.formPerson.birthDate = this.editedItem.birthDate
          ? this.$formatDateDb(this.editedItem.birthDate)
          : "";
        this.formPerson.birthPlace = this.editedItem.birthPlace
          ? this.editedItem.birthPlace
          : "";
        this.formPerson.activity = this.editedItem.activity
          ? this.editedItem.activity
          : "";
        if (this.personaFisica) {
          this.formPerson.firstName = this.editedItem.firstName
            ? this.editedItem.firstName
            : "";
          this.formPerson.lastName = this.editedItem.lastName
            ? this.editedItem.lastName
            : "";
          this.formPerson.genderId = this.editedItem.genderId
            ? Number(this.editedItem.genderId.id)
            : 0;
          this.formPerson.maritalStatusId = this.editedItem.maritalStatusId
            ? Number(this.editedItem.maritalStatusId.id)
            : 0;
        }
        if (this.editedItem.tipo_cuitcuil) {
          if (this.editedItem.id) {
            this.formPerson.identifiers.push({
              identifierTypeId: this.editedItem.tipo_cuitcuil
                ? Number(this.editedItem.tipo_cuitcuil.id)
                : "",
              identifier: this.editedItem.cuit,
              id: this.editedItem.idCuitCuil,
            });
          } else {
            this.formPerson.identifiers.push({
              identifierTypeId: this.editedItem.tipo_cuitcuil
                ? Number(this.editedItem.tipo_cuitcuil.id)
                : "",
              identifier: this.editedItem.cuit,
            });
          }
        }
        if (this.editedItem.id) {
          this.formPerson.identifiers.push({
            identifierTypeId: this.editedItem.tipo_documento.id,
            identifier: this.editedItem.documento,
            id: this.editedItem.idDocumento,
          });
        } else {
          this.formPerson.identifiers.push({
            identifierTypeId: this.editedItem.tipo_documento.id,
            identifier: this.editedItem.documento,
          });
        }
        if (this.editedItem.id) {
          this.formPerson.emails = [
            {
              email: this.editedItem.mail,
              comments: "email",
              id: this.editedItem.idMail,
            },
          ];
          this.formPerson.phones = [
            {
              phoneNumber: this.editedItem.telefono,
              id: this.editedItem.idPhone,
            },
          ];
        } else {
          this.formPerson.emails = [
            {
              email: this.editedItem.mail,
              comments: "email",
            },
          ];
          this.formPerson.phones = [
            {
              phoneNumber: this.editedItem.telefono,
            },
          ];
        }
        //console.log(this.editedItem.domicilioLegal);

        if (this.editedItem.domicilioLegal) {
          if (this.editedItem.id) {
            this.formPerson.addresses.push({
              id: this.editedItem.idPersDomLegal,
              address: {
                id: this.editedItem.idDomLegal,
                street: this.editedItem.domicilioLegal.calle
                  ? this.editedItem.domicilioLegal.calle
                  : "",
                number: this.editedItem.domicilioLegal.numero
                  ? this.editedItem.domicilioLegal.numero.toString()
                  : "",

                postCode: this.editedItem.domicilioLegal.cp
                  ? this.editedItem.domicilioLegal.cp.toString()
                  : "",
                location: this.editedItem.domicilioLegal.localidad
                  ? this.editedItem.domicilioLegal.localidad
                  : "",
                partido: this.editedItem.domicilioLegal.partido
                  ? this.editedItem.domicilioLegal.partido
                  : "",
                province: this.editedItem.domicilioLegal.provincia
                  ? this.editedItem.domicilioLegal.provincia.name
                  : "",
                neighborhood: this.editedItem.domicilioLegal.barrio
                  ? this.editedItem.domicilioLegal.barrio.name
                  : "",
                isActive: true,
              },
              addressTypeId: 2,
            });
          } else {
            this.formPerson.addresses.push({
              address: {
                street: this.editedItem.domicilioLegal.calle
                  ? this.editedItem.domicilioLegal.calle
                  : "",
                number: this.editedItem.domicilioLegal.numero
                  ? this.editedItem.domicilioLegal.numero.toString()
                  : "",

                postCode: this.editedItem.domicilioLegal.cp
                  ? this.editedItem.domicilioLegal.cp.toString()
                  : "",
                location: this.editedItem.domicilioLegal.localidad
                  ? this.editedItem.domicilioLegal.localidad
                  : "",
                partido: this.editedItem.domicilioLegal.partido
                  ? this.editedItem.domicilioLegal.partido
                  : "",
                province: this.editedItem.domicilioLegal.provincia
                  ? this.editedItem.domicilioLegal.provincia.name
                  : "",
                neighborhood: this.editedItem.domicilioLegal.barrio
                  ? this.editedItem.domicilioLegal.barrio.name
                  : "",
                isActive: true,
              },
              addressTypeId: 2,
            });
          }
        }

        if (this.editedItem.domicilioReal) {
          if (this.editedItem.id) {
            this.formPerson.addresses.push({
              id: this.editedItem.idPersDomReal,
              address: {
                id: this.editedItem.idDomReal,
                street: this.editedItem.domicilioReal.calle
                  ? this.editedItem.domicilioReal.calle
                  : "",
                number: this.editedItem.domicilioReal.numero
                  ? this.editedItem.domicilioReal.numero.toString()
                  : "",

                postCode: this.editedItem.domicilioReal.cp
                  ? this.editedItem.domicilioReal.cp.toString()
                  : "",
                location: this.editedItem.domicilioReal.localidad
                  ? this.editedItem.domicilioReal.localidad
                  : "",
                partido: this.editedItem.domicilioReal.partido
                  ? this.editedItem.domicilioReal.partido
                  : "",
                province: this.editedItem.domicilioReal.provincia
                  ? this.editedItem.domicilioReal.provincia.name
                  : "",
                neighborhood: this.editedItem.domicilioReal.barrio
                  ? this.editedItem.domicilioReal.barrio.name
                  : "",
                isActive: true,
              },
              addressTypeId: 1,
            });
          } else {
            this.formPerson.addresses.push({
              address: {
                street: this.editedItem.domicilioReal.calle
                  ? this.editedItem.domicilioReal.calle
                  : "",
                number: this.editedItem.domicilioReal.numero
                  ? this.editedItem.domicilioReal.numero.toString()
                  : "",

                postCode: this.editedItem.domicilioReal.cp
                  ? this.editedItem.domicilioReal.cp.toString()
                  : "",
                location: this.editedItem.domicilioReal.localidad
                  ? this.editedItem.domicilioReal.localidad
                  : "",
                partido: this.editedItem.domicilioReal.partido
                  ? this.editedItem.domicilioReal.partido
                  : "",
                province: this.editedItem.domicilioReal.provincia
                  ? this.editedItem.domicilioReal.provincia.name
                  : "",
                neighborhood: this.editedItem.domicilioReal.barrio
                  ? this.editedItem.domicilioReal.barrio.name
                  : "",
                isActive: true,
              },
              addressTypeId: 1,
            });
          }
        }
        let tituloSwal = this.editedItem.id ? "Actualizar" : "Guardar";
        let tituloSwalOk = this.editedItem.id ? "actualizaron" : "registraron";
        console.log(this.formPerson);
        Swal.fire({
          title: "<b>" + tituloSwal + " Datos del " + this.title + "?</b>",
          icon: "question",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: true,
          confirmButtonColor: "green",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            if (this.editedItem.id) {
              Swal.alertGetInfo("Actualizando Datos de la Persona");
              this.formPerson.id = this.editedItem.id;
              this.$axiosApi
                .putExternal(this.route, this.formPerson)
                .then((r) => {
                  if (
                    typeof r !== "undefined" &&
                    r !== null &&
                    (r.status == 200 || r.status == 201 || r.succeeded == true)
                  ) {
                    this.editedItem.id = r.data.data.id;
                    //this.item = this.editedItem;
                    Swal.close();
                    Swal.fire({
                      title: "Se " + tituloSwalOk + " los datos de la persona ",
                      icon: "success",
                      html: "Puede continuar cargando la información del trámite en la siguiente sección",
                      showCloseButton: false,
                      showCancelButton: false,
                      focusConfirm: true,
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "Aceptar",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.$emit("save-persona", this.editedItem);
                        this.close();
                      }
                    });
                  } else {
                    Swal.close();
                    console.log(this.formPerson);
                    Swal.fire({
                      icon: "error",
                      title: "Registrar Persona",
                      text: "Ha ocurrido un error al intentar registrar los datos de la persona",
                    });

                    return;
                  }
                })
                .catch((e) => {
                  Swal.close();
                  this.snackbar = true;
                  this.text =
                    "Error al ingresar los datos. Error: " + e.message;
                  this.color = "error";
                });
            } else {
              Swal.alertGetInfo("Guardando Datos de la Persona");
              this.$axiosApi
                .postExternal(this.route, this.formPerson)
                .then((r) => {
                  //console.log(r.data.data.id);
                  if (
                    typeof r !== "undefined" &&
                    r !== null &&
                    (r.status == 200 || r.status == 201 || r.succeeded == true)
                  ) {
                    this.editedItem.id = r.data.data.id;
                    //this.item = this.editedItem;
                    Swal.close();
                    Swal.fire({
                      title: "Se " + tituloSwalOk + " los datos de la persona ",
                      icon: "success",
                      html: "Puede continuar cargando la información del trámite en la siguiente sección",
                      showCloseButton: false,
                      showCancelButton: false,
                      focusConfirm: true,
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "Aceptar",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.$emit("save-persona", this.editedItem);
                        this.close();
                      }
                    });
                  } else {
                    Swal.close();
                    Swal.fire({
                      icon: "error",
                      title: "Registrar Persona",
                      text: "Ha ocurrido un error al intentar registrar los datos de la persona",
                    });

                    return;
                  }
                })
                .catch((e) => {
                  Swal.close();
                  console.log(e);
                  this.snackbar = true;
                  this.text =
                    "Error al ingresar los datos. Error: " + e.message;
                  this.color = "error";
                });
            }

            //this.$alertGetInfo("Registrando información del tramite");
          }
        });
      }
    },
    formatDate(date) {
      try {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      } catch (error) {
        console.log("formatDate Error");
      }
    },
    parseDate(date) {
      try {
        if (!date) return null;
        if (date.length !== 10) {
          this.editedItem.birthDate = this.formatDate(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          );
          return;
        }
        const [month, day, year] = date.split("/");
        return `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
      } catch (error) {
        console.log("parseDate Error");
      }
    },
    dateValido() {
      if (!this.editedItem.birthDate) {
        this.editedItem.birthDate = this.formatDate(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        );
      }
    },
    datePreSet() {
      if (this.editedItem.birthDate) {
        console.log(this.editedItem.birthDate);
        const [month, day, year] = this.editedItem.birthDate.split("/");
        this.date = `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
      }
    },
  },
  mounted() {
    console.log("Componente " + getTitle() + " creado");
    this.datePreSet();
  },
  watch: {
    date(val) {
      this.editedItem.birthDate = this.formatDate(this.date);
    },
  },
};
</script>
<style>
.icon-size {
  width: 80px !important;
  height: 80px !important;
}

.bg-gris {
  background-color: #eee;
}
</style>
