<template>
  <v-card id="views\personas\components\PersonaForm">
    <v-form ref="personaForm" v-model="valid" lazy-validation>
      <v-toolbar
        dark
        :color="Number(editedItem.index) == 0 ? 'primary' : 'primary-2'"
      >
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="save()">Guardar</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="card-scrolleable" style="max-height: 91vh">
        <v-container>
          <v-row justify="center">
            <v-col
              cols="12"
              md="1"
              sm="12"
              style="margin-right: -14px"
              class="pa-0"
            >
              <v-img
                class="icon-size"
                :src="editedItem.index == 0 ? avatar : avatarCondomino"
              ></v-img>
            </v-col>
            <!--<v-col
							cols="12"
							md="2"
							sm="12"
							style="border-radius: 10px"
							class="pa-0"
						>

							<div
								:class="editedItem.index == 0 ? 'primary' : 'primary-2'"
								class="py-7 rounded-pill ml-0 mr-1 text-center text-white"
								default
								:color="editedItem.index == 0 ? 'primary' : 'primary-2'"
							>
								{{
									editedItem.index == 0
										? "Titular N°: " + editedItem.numero
										: "Condómino N°: " + editedItem.numero
								}}
							</div>

						</v-col>-->
            <v-col cols="12" md="11" sm="12" class="pl-0">
              <v-row
                justify="center"
                class="rounded-pill ml-0 mr-0 pr-6"
                :style="{
                  backgroundColor:
                    (item.index + 1) % 2 != 0
                      ? $cv('primary') + '20'
                      : $cv('color') + '20',
                }"
              >
                <v-col cols="12" md="3" sm="12" class="pa-0 pl-4">
                  <v-text-field
                    v-model="editedItem.formulario_01"
                    label="Formulario 01"
                    class="input-h1"
                    :rules="[$rulesRequerido, $rulesFormularios]"
                    maxLength="8"
                    :disabled="formularioDisabled()"
                    :title="
                      Number(editedItem.index) % 2 != 0
                        ? 'El condomino posee el mismo numero de formulario que el titular'
                        : ''
                    "
                    prepend-inner-icon="mdi-file-document-check"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="pa-0 pl-4">
                  <v-autocomplete
                    :items="tipoPersonas"
                    item-value="id"
                    item-text="name"
                    label="Tipo de Persona"
                    v-model="editedItem.personType"
                    @change="showTipoPersona()"
                    :rules="[$rulesRequerido]"
                    return-object
                    class="input-h1"
                    prepend-inner-icon="mdi-badge-account-outline"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" md="3" sm="12" class="pa-0 pl-4">
                  <v-select
                    :items="personaFisica == true ? tiposCuitCuil : tiposCuit"
                    item-value="id"
                    item-text="name"
                    label="Cuil/Cuit"
                    v-model="editedItem.tipo_cuitcuil"
                    :rules="[$rulesRequerido]"
                    return-object
                    class="input-h1"
                    @change="
                      $verifyIdentifiers(
                        editedItem.tipo_cuitcuil,
                        editedItem.cuit
                      )
                    "
                    prepend-inner-icon="mdi-badge-account-outline"
                  ></v-select
                ></v-col>
                <v-col cols="12" md="3" sm="12" class="pa-0 pl-4">
                  <v-text-field
                    v-model="editedItem.cuit"
                    label="CUIT/CUIL"
                    :rules="[$rulesRequerido, $rulesCuit]"
                    hint="Ingrese solo números"
                    maxLength="13"
                    class="input-h1"
                    @blur="
                      $verifyIdentifiers(
                        editedItem.tipo_cuitcuil,
                        editedItem.cuit
                      );
                      setDNI();
                    "
                    v-mask="'##-########-#'"
                    prepend-inner-icon="mdi-card-account-details"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--
          <v-row justify="center">
            <v-col cols="12" sm="9" class="pa-0 pl-4">
              <v-divider  class="mt-4 pa-2 " :class="Number(editedItem.index) % 2 == 0 ? 'border-primary' : 'border-primary-2'"
          ></v-divider>
            </v-col>
          </v-row>
-->
          <template v-if="personaFisica">
            <v-row justify="center">
              <v-col cols="12" sm="12" class="pa-0 pl-4 pr-4">
                <v-divider
                  class="mt-2 pa-2"
                  :class="
                    Number(editedItem.index) % 2 == 0
                      ? 'border-primary'
                      : 'border-primary-2'
                  "
                ></v-divider>
              </v-col>
            </v-row>
            <v-row
              :style="{
                borderRadius: '10px',
                margin: '4px',
                backgroundColor:
                  (item.index + 1) % 2 != 0
                    ? $cv('primary') + '20'
                    : $cv('color') + '20',
              }"
            >
              <v-col>
                <v-row>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-autocomplete
                      :items="tiposDocumentos"
                      item-value="id"
                      item-text="name"
                      label="Tipo Documento"
                      v-model="editedItem.tipo_documento"
                      :rules="[$rulesRequerido]"
                      return-object
                      class="input-h1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.documento"
                      label="Documento"
                      :rules="[$rulesRequerido, $rulesDni]"
                      hint="Ingrese solo números"
                      class="input-h1"
                      maxLength="8"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.lastName"
                      label="Apellido"
                      :rules="[$rulesRequerido, $rulesAlfaNum]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.firstName"
                      label="Nombre"
                      :rules="[$rulesRequerido, $rulesAlfaNum]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.porcentaje"
                      label="Porcentaje de Titularidad"
                      @input="calcularPorcentajeRestante()"
                      :error-messages="porcentajeError"
                      :rules="[$rulesRequerido, $rulesPorcentaje]"
                      :hint="hintMensajePorcentaje"
                      :readonly="cantTitulares == 1 ? true : false"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.birthDate"
                          label="Fecha de Nacimiento"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="date = parseDate(editedItem.birthDate)"
                          v-on="on"
                          v-on:focus="dateValido"
                          :rules="[
                            $rulesRequerido,
                            $rulesFechaValida,
                            $rulesFechaMenorAHoy,
                          ]"
                          v-mask="'##/##/####'"
                          placeholder="dd/mm/yyyy"
                          maxLength="10"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        class="ma-0"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.birthPlace"
                      label="Lugar de Nacimiento"
                      :rules="[$rulesRequerido]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-autocomplete
                      :items="genders"
                      item-value="id"
                      item-text="name"
                      label="Sexo"
                      v-model="editedItem.genderId"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                  <v-col
                    cols="12"
                    :md="
                      editedItem.maritalStatusId &&
                      ['DIVORCIADO/A', 'VIUDO/A'].includes(
                        editedItem.maritalStatusId.name
                      )
                        ? 1
                        : 3
                    "
                    sm="12"
                    class="py-0"
                  >
                    <v-autocomplete
                      :items="maritalstatuses"
                      item-value="id"
                      item-text="name"
                      label="Estado Civil"
                      v-model="editedItem.maritalStatusId"
                      @change="updateMaritalStatusId"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="12"
                    class="py-0"
                    v-if="
                      editedItem.maritalStatusId &&
                      ['DIVORCIADO/A', 'VIUDO/A'].includes(
                        editedItem.maritalStatusId.name
                      )
                    "
                  >
                    <v-text-field
                      v-model="editedItem.maritalStatusId.numeroNupcias"
                      label="Nro. de Nupcias"
                      :rules="[$rulesRequerido, $rulesNupcias]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-autocomplete
                      :items="paises"
                      item-value="id"
                      item-text="name"
                      label="Autoridad que expidió el documento"
                      v-model="editedItem.pais_dni"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-autocomplete
                      :items="paises"
                      item-value="id"
                      item-text="name"
                      label="Nacionalidad"
                      v-model="editedItem.nacionalidad"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.activity"
                      label="Ocupación"
                      :rules="[$rulesRequerido]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.mail"
                      label="Email"
                      :rules="[$rulesRequerido, $rulesMail]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.telefono"
                      label="Telefono"
                      :rules="[$rulesRequerido, $rulesTelefono]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-checkbox
                      class="mt-4"
                      v-model="editedItem.poseeapoderados"
                      label="Posee Apoderado"
                      @change="setPoseeApoderados()"
                      filled
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--<v-row>
							<v-col>
								<v-row>
									<v-col cols="12" md="12" sm="12" class="py-0">
										<v-card id="apoderados" outlined v-show="showApoderados">
											<v-card-title
												:class="
													(editedItem.index + 1) % 2 != 0
														? 'primary text-white'
														: 'primary-2  text-white'
												"
												class="text-h3 text-left"
											>
												<v-row wrap>
													<v-col cols="12" :xs="12"> Apoderados </v-col>
												</v-row>
											</v-card-title>

											<v-card-text>
												<v-container>
													<v-row>
														<v-card-text class="px-0">
															<ApoderadosRow
																:key="componentApoderados"
																ref="apoderados"
																:items="Number(editedItem.apoderados)"
																:editedItem="editedItem"
															></ApoderadosRow>
														</v-card-text>
													</v-row>
												</v-container> </v-card-text
										></v-card> </v-col></v-row></v-col
						></v-row>
            -->
          </template>
          <template v-else>
            <v-row justify="center">
              <v-col cols="12" sm="12" class="pa-0 pl-4 pr-4">
                <v-divider
                  class="mt-2 pa-2"
                  :class="
                    Number(editedItem.index) % 2 == 0
                      ? 'border-primary'
                      : 'border-primary-2'
                  "
                ></v-divider>
              </v-col>
            </v-row>
            <v-row
              :style="{
                borderRadius: '10px',
                margin: '4px',
                backgroundColor:
                  (item.index + 1) % 2 != 0
                    ? $cv('primary') + '20'
                    : $cv('color') + '20',
              }"
              ><v-col>
                <v-row>
                  <v-col cols="12" md="5" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.businessName"
                      label="Razon Social"
                      :rules="[$rulesRequerido, $rulesAlfaNum]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="5" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.activity"
                      label="Actividad"
                      :rules="[$rulesRequerido, $rulesAlfaNum]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.porcentaje"
                      label="Porcentaje de Titularidad"
                      @input="calcularPorcentajeRestante()"
                      :error-messages="porcentajeError"
                      :rules="[$rulesRequerido, $rulesPorcentaje]"
                      :hint="hintMensajePorcentaje"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-autocomplete
                      :items="paises"
                      item-value="id"
                      item-text="name"
                      label="Autoridad que lo expidió"
                      v-model="editedItem.pais_dni"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-autocomplete
                      :items="entidades"
                      item-value="id"
                      item-text="name"
                      label="Personeria Otorgada por"
                      v-model="editedItem.otorgada_por"
                      :rules="[$rulesRequerido]"
                      return-object
                    ></v-autocomplete
                  ></v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.numero_inscripcion"
                      label="Numero de Inscripción"
                      :rules="[$rulesRequerido, $rulesAlfaNum]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.birthDate"
                          label="Fecha de Inscripción o Creación"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="date = parseDate(editedItem.birthDate)"
                          v-on="on"
                          v-on:focus="dateValido"
                          :rules="[
                            $rulesRequerido,
                            $rulesFechaValida,
                            $rulesFechaMenorAHoy,
                          ]"
                          v-mask="'##/##/####'"
                          placeholder="dd/mm/yyyy"
                          maxLength="10"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        class="ma-0"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="5" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.mail"
                      label="Email"
                      :rules="[$rulesRequerido, $rulesMail]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.telefono"
                      label="Telefono"
                      :rules="[$rulesRequerido, $rulesTelefono]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="py-0">
                    <v-text-field
                      v-model="editedItem.apoderados"
                      label="Cantidad de apoderados"
                      :rules="[$rulesRequerido, $rulesEnterosPositivos]"
                      @keydown="
                        cantApoderadosOldValue =
                          editedItem.apoderados > 0
                            ? editedItem.apoderados
                            : cantApoderadosOldValue
                      "
                      @keyup="validateApoderados"
                      maxLength="3"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="py-0 pb-4">
                        <v-card
                          id="apoderados"
                          outlined
                          v-show="showApoderados"
                        >
                          <v-card-title
                            :class="
                              (editedItem.index + 1) % 2 != 0
                                ? 'primary text-white'
                                : 'primary-2  text-white'
                            "
                            class="text-h3 text-left"
                          >
                            <v-row wrap>
                              <v-col cols="12" :xs="12"> Apoderados </v-col>
                            </v-row>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-card-text class="px-0">
                                  <ApoderadosRow
                                    :key="componentApoderados"
                                    ref="apoderados"
                                    :items="Number(editedItem.apoderados)"
                                    :editedItem="editedItem"
                                    :records="datosApoderados"
                                  ></ApoderadosRow>
                                </v-card-text>
                              </v-row>
                            </v-container> </v-card-text
                        ></v-card> </v-col></v-row></v-col
                ></v-row>
              </v-col>
            </v-row>
            <!--
						<v-row>
							<v-col cols="12" md="12" sm="12">
								<base-material-card color="primary" class="px-5 py-3">
									<template v-slot:heading>
										<div class="text-h5 font-weight-light">
											Sección de Titulares
											{{
												editedItem.apoderados
													? "(" + editedItem.apoderados + ")"
													: ""
											}}
											<v-btn
												class=""
												icon
												@click="showApoderados = !showApoderados"
											>
												<v-icon>{{
													showApoderados ? "mdi-chevron-up" : "mdi-chevron-down"
												}}</v-icon>
											</v-btn>
										</div>
									</template>
									<v-card-text>
										<v-expand-transition> </v-expand-transition>
									</v-card-text>
								</base-material-card>
							</v-col>
						</v-row>
            -->
          </template>

          <v-row class="pt-3">
            <v-col cols="12" md="6" sm="12" class="py-0">
              <DomicilioForm
                ref="domicilioLegal"
                :item="editedItem.domicilioLegal"
                title="Domicilio Legal"
                :key="keyDomicilioLegal"
                :index="editedItem.index"
                :indexPadre="editedItem.index"
              ></DomicilioForm>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <DomicilioForm
                ref="domicilioReal"
                :item="editedItem.domicilioReal"
                title="Domicilio Real"
                :showCopy="true"
                :key="keyDomicilioReal"
                @copiar-domicilio="copyDomicilio"
                :index="editedItem.index"
                :indexPadre="editedItem.index"
              ></DomicilioForm>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-card>
</template>

<script>
function getTitle() {
  return "personas";
}
import DomicilioForm from "../../personas/components/DomicilioForm";
import ApoderadosRow from "../../tramites/component/ApoderadosRowComponent";

export default {
  name: "PersonaForm",
  components: {
    DomicilioForm,
    ApoderadosRow,
  },
  props: {
    title: { String, default: null },
    item: { Object, default: null },
    titulares: { Object, default: null },
    tipoPersona: { Number, default: 1 },
    cantTitulares: { Number, default: null },
    tramite: { Object, default: null },
  },
  data: (vm) => ({
    showApoderados: false,
    componentApoderados: 0,
    keyDomicilioLegal: 0,
    keyDomicilioReal: 1,
    personaFisica: true,
    valid: true,
    route: "personsAggregator",
    routePersonRelationship: "personRelationship",
    routePersons: "persons",
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    editedIndex: -1,
    editedItem: {
      domicilioLegal: {},
      domicilioReal: {},
      poseeapoderados: true,
      maritalStatusId: { name: "", id: null, numeroNupcias: 0 },
      activity: "",
      lastName: "",
      birthDate: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
    },
    formDomicilioLegal: {},
    formDomicilioReal: {},
    defaultItem: { domicilioLegal: {}, domicilioReal: {} },
    genders: [],
    identifiertypes: [],
    tiposCuitCuil: [],
    tiposCuit: [],
    tiposDocumentos: [],
    maritalstatuses: [],
    apoderados: [],
    /*tipoPersonas: [
																																	{ id: 1, name: "PERSONA FÍSICA" },
																																	{ id: 2, name: "PERSONA JURÍDICA" },
																																],*/
    tipoPersonas: [],
    entidades: [
      { id: 1, name: "AGIP" },
      { id: 2, name: "IGJ" },
      { id: 3, name: "REGISTRO PUBLICO DE COMERCIO" },
    ],
    paises: [],
    avatar: "/img/user_primary.png",
    avatarCondomino: "/img/user_primary_2.png",
    formPerson: { identifiers: [], addresses: [] },
    tDocAnt: "",
    docAnt: "",
    porcentajeRestante: 100,
    porcentajeError: "",
    cantApoderadosRegistrados: 0,
    datosApoderados: [],
    cantApoderadosOldValue: 0,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
  }),

  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (date) {
        return date;
      },
    },
    formTitle() {
      return this.editedIndex === -1
        ? this.title
          ? "Registrar datos de " + this.title
          : "Registrar datos de " + getTitle()
        : this.title
        ? "Editar datos de " + this.title
        : "Editar datos de " + getTitle();
    },
    hintMensajePorcentaje() {
      return this.porcentajeRestante < 100
        ? "Porcentaje disponible: " + this.porcentajeRestante + "%"
        : "Ingrese un valor mayor a 0 y menor o igual que 100";
    },
  },

  created() {
    this.editedItem = Object.assign({}, this.item);
    if (typeof this.item.datos_apoderados !== "undefined") {
      this.datosApoderados = this.item.datos_apoderados;
      this.setApoderados();
    }
    this.tipoPersonas = this.$getPersonsTypes();
    //console.log(this.item);
    //console.log(this.editedItem);
    this.showTipoPersona();

    this.$getData(["paises", "identifiertypes", "genders", "maritalstatuses"]);

    if (this.editedItem.id) {
      this.tDocAnt = this.editedItem.tipo_cuitcuil.id;
      this.docAnt = this.editedItem.cuit;
    }

    /*if (this.tipoPersona == 3) {
																																																																																			      this.editedItem.tipo_persona.id = 1;
																																																																																			      this.personaFisica = true;
																																																																																			    }*/
    //this.setDatosPrueba();

    this.calcularPorcentajeRestante();
  },

  methods: {
    async savePersonRelationship(personId) {
      const forLoop = async (personId) => {
        //this.$alertGetInfo("Registrando Datos de los Representantes Legales");
        //console.log("Start asociando apoderados...");

        for (let i = 0; i < this.$refs.apoderados.apoderados.length; i++) {
          //console.log("asociando apoderados...");
          //console.log(this.$refs.apoderados.apoderados[i]);
          let formPersonRelationship = {
            personId: personId,
            relatedPersonId: this.$refs.apoderados.apoderados[i].id,
            relationshipTypeId: 1,
          };
          const resp = await this.$axiosApi
            .postExternal(this.routePersonRelationship, formPersonRelationship)
            .then((r) => {
              //console.log(r.status);
              if (
                !(
                  typeof r !== "undefined" &&
                  r !== null &&
                  (r.status == 200 || r.status == 201 || r.succeeded == true)
                )
              ) {
                this.snackbar = true;
                this.text = "Error al asociar respresentantes. Error: " + r;
                this.color = "error";
              }
            })
            .catch((e) => {
              return e;
            });
        }
        //Swal.close();
        console.log("End asociando apoderados...");
        return 1;
      };
      let resp = await forLoop(personId);

      if (resp == 1) {
        Swal.close();
        Swal.fire({
          title: "Se registraron los datos de la persona ",
          icon: "success",
          html: "Puede continuar cargando la información del trámite en la siguiente sección",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("save-persona", this.editedItem);
            this.close();
          }
        });
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Registrar Persona",
          text: "Ha ocurrido un error al intentar asociar los representantes legales",
        });

        return;
      }
    },
    updateMaritalStatusId(value) {
      this.$set(this.editedItem, "maritalStatusId", value);
    },
    setDatosPrueba() {
      //this.editedItem.formulario_01 = "12345678";
      //this.editedItem.personType = { id: 1, name: "PERSONA FÍSICA" };
      //this.editedItem.personType = { id: 2, name: "PERSONA JURÍDICA" };
      this.editedItem.businessName = "ABERTURAS DEL SUR";
      this.editedItem.cuit = "20308762231";
      this.editedItem.numero_inscripcion = "11223344";
      this.editedItem.otorgada_por = { id: 1, name: "AGIP" };
      this.editedItem.tipo_documento = { id: 13, name: "DNI" };
      this.editedItem.documento = "30987222";
      this.editedItem.lastName = "APELLIDO PERSONA";
      this.editedItem.firstName = "NOMBRE PERSONA";
      this.editedItem.porcentaje = "30";
      this.editedItem.birthDate = "12/09/19783";
      this.editedItem.birthPlace = "ADROGUE";
      this.editedItem.genderId = { id: 1, name: "Masculino" };
      this.editedItem.maritalStatusId = { id: 2, name: "SOLTERO/A" };
      this.editedItem.pais_dni = { id: "ARGENTINA" };
      this.editedItem.nacionalidad = { id: "ARGENTINA" };
      this.editedItem.activity = "EMPLEADO INDUSTRIAL";
      this.editedItem.mail = "mail@mail.com";
      this.editedItem.telefono = "1111111111";
    },
    validateApoderados() {
      const oldValue = Number(this.cantApoderadosOldValue);
      const newValue = Number(this.editedItem.apoderados);
      console.log(oldValue);
      console.log(newValue);
      if (oldValue == 0) {
        this.setApoderados();
        return;
      }
      if (newValue > 0) {
        if (newValue < oldValue) {
          Swal.fire({
            title: "<b>Modificar Cantidad de Apoderados?</b>",
            icon: "question",
            html:
              "Si reduce la cantidad de apoderados a (<b>" +
              Number(newValue) +
              "</b>), se perderan algunos datos previamente cargados (<b>" +
              Number(oldValue) +
              "</b>). Confirma la modificación?",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            if (result.isConfirmed) {
              let posFin = Number(oldValue) - 1;
              this.datosApoderados = this.$refs.apoderados.apoderados;
              this.datosApoderados.splice(posFin, newValue);
              //oldValue = this.editedItem.apoderados;
              this.setApoderados();
            } else {
              this.editedItem.apoderados = oldValue;
            }
          });
        } else if (newValue > oldValue) {
          this.datosApoderados = this.$refs.apoderados.apoderados;
          this.setApoderados();
        }
      }
    },
    setApoderados() {
      this.componentApoderados = this.componentApoderados + 1;
      this.showApoderados = true;
      this.apoderados = this.editedItem.apoderados;
    },
    setPoseeApoderados() {
      if (this.editedItem.apoderados == 0) {
        this.editedItem.apoderados = 1;
        this.componentApoderados = this.componentApoderados + 1;
        this.showApoderados = true;
        this.apoderados = this.editedItem.apoderados;
      } else {
        this.editedItem.apoderados = 0;
        this.componentApoderados = this.componentApoderados + 1;
        this.showApoderados = false;
        this.apoderados = this.editedItem.apoderados;
      }
    },
    showTipoPersona() {
      if (this.editedItem.personType) {
        if (typeof this.editedItem.personType === "object") {
          this.personaFisica =
            this.editedItem.personType.id == 1 ? true : false;
        } else {
          this.personaFisica =
            Number(this.editedItem.personType) == 1 ? true : false;
        }
      }
      this.permitirSoloPersonaHumana();
      //this.editedItem = Object.assign({}, this.defaultItem);
      //this.editedItem.lastName = "";
      //this.$refs.personaForm.resetValidation();
    },
    copyDomicilio(v) {
      if (v == true) {
        this.editedItem.domicilioReal = this.editedItem.domicilioLegal;
        //this.formDomicilioReal = this.formDomicilioLegal;
        //this.keyDomicilioReal = +1;
      } else {
        this.editedItem.domicilioReal = {};
      }
    },
    reset() {
      //this.$refs.personaForm.reset();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation();
    },
    close() {
      //this.dialog = false;
      this.reset();
      this.$emit("send-message");
    },
    resetValidation() {
      this.$refs.personaForm.resetValidation();
    },

    save() {
      //console.log(this.$refs.apoderados.apoderados);
      if (this.$refs.personaForm.validate() && this.porcentajeError === "") {
        if (this.validarApoderados()) {
          this.formPerson.addresses = [];
          this.formPerson.identifiers = [];
          this.formPerson.personType = Number(this.editedItem.personType.id);
          this.formPerson.businessName = this.editedItem.businessName
            ? this.editedItem.businessName
            : "";
          this.formPerson.birthDate = this.editedItem.birthDate
            ? this.$formatDateDb(this.editedItem.birthDate)
            : "";
          this.formPerson.birthPlace = this.editedItem.birthPlace
            ? this.editedItem.birthPlace
            : "";
          this.formPerson.nationality = this.editedItem.pais_dni
            ? this.editedItem.pais_dni.name
            : "";
          this.formPerson.activity = this.editedItem.activity
            ? this.editedItem.activity
            : "";
          if (this.editedItem.tipo_cuitcuil) {
            if (this.editedItem.id) {
              this.formPerson.identifiers.push({
                identifierTypeId: this.editedItem.tipo_cuitcuil
                  ? Number(this.editedItem.tipo_cuitcuil.id)
                  : "",
                identifier: this.editedItem.cuit,
                id: this.editedItem.idCuitCuil,
              });
            } else {
              this.formPerson.identifiers.push({
                identifierTypeId: this.editedItem.tipo_cuitcuil
                  ? Number(this.editedItem.tipo_cuitcuil.id)
                  : "",
                identifier: this.editedItem.cuit,
              });
            }
          }
          if (this.personaFisica) {
            //console.log(this.formPerson.identifiers);
            //console.log(this.editedItem.id);
            if (this.editedItem.id) {
              /*this.formPerson.identifiers.push({
                identifierTypeId: this.editedItem.tipo_cuitcuil
                  ? Number(this.editedItem.tipo_cuitcuil.id)
                  : "",
                identifier: this.editedItem.cuit,
                id: this.editedItem.idCuitCuil,
              });*/
              this.formPerson.identifiers.push({
                identifierTypeId: this.editedItem.tipo_documento.id,
                identifier: this.editedItem.documento,
                id: this.editedItem.idDocumento,
              });
            } else {
              /*this.formPerson.identifiers.push({
                identifierTypeId: this.editedItem.tipo_cuitcuil
                  ? Number(this.editedItem.tipo_cuitcuil.id)
                  : "",
                identifier: this.editedItem.cuit,
              });*/
              this.formPerson.identifiers.push({
                identifierTypeId: this.editedItem.tipo_documento.id,
                identifier: this.editedItem.documento,
              });
            }

            this.formPerson.firstName = this.editedItem.firstName
              ? this.editedItem.firstName
              : "";
            this.formPerson.lastName = this.editedItem.lastName
              ? this.editedItem.lastName
              : "";
            this.formPerson.genderId = this.editedItem.genderId
              ? Number(this.editedItem.genderId.id)
              : 0;
            this.formPerson.maritalStatusId = this.editedItem.maritalStatusId
              ? Number(this.editedItem.maritalStatusId.id)
              : 0;
          } else {
            let idPersJur = this.identifiertypes.filter(
              (item) => item.name.toUpperCase() == "PERSONERIA JURIDICA"
            );
            if (this.editedItem.id) {
              this.formPerson.identifiers.push({
                identifierTypeId: idPersJur[0] ? Number(idPersJur[0].id) : "",
                identifier: this.editedItem.numero_inscripcion
                  ? this.editedItem.numero_inscripcion
                  : "",
                issuedBy: this.editedItem.otorgada_por
                  ? this.editedItem.otorgada_por.name
                  : "",
                id: this.editedItem.idDocumento,
              });
            } else {
              this.formPerson.identifiers.push({
                identifierTypeId: idPersJur[0] ? Number(idPersJur[0].id) : "",
                identifier: this.editedItem.numero_inscripcion
                  ? this.editedItem.numero_inscripcion
                  : "",
                issuedBy: this.editedItem.otorgada_por
                  ? this.editedItem.otorgada_por.name
                  : "",
              });
            }

            this.formPerson.startOfActivityDate = this.editedItem.birthDate
              ? this.$formatDateDb(this.editedItem.birthDate)
              : "";
          }
          if (this.editedItem.id) {
            this.formPerson.emails = [
              {
                email: this.editedItem.mail,
                comments: "email",
                id: this.editedItem.idMail,
              },
            ];
            this.formPerson.phones = [
              {
                phoneNumber: this.editedItem.telefono,
                id: this.editedItem.idPhone,
              },
            ];
          } else {
            this.formPerson.emails = [
              {
                email: this.editedItem.mail,
                comments: "email",
              },
            ];
            this.formPerson.phones = [
              {
                phoneNumber: this.editedItem.telefono,
              },
            ];
          }

          if (this.editedItem.domicilioLegal) {
            if (this.editedItem.id) {
              this.formPerson.addresses.push({
                id: this.editedItem.idPersDomLegal,
                address: {
                  id: this.editedItem.idDomLegal,
                  street: this.editedItem.domicilioLegal.calle
                    ? this.editedItem.domicilioLegal.calle
                    : "",
                  number: this.editedItem.domicilioLegal.numero
                    ? this.editedItem.domicilioLegal.numero.toString()
                    : "",

                  postCode: this.editedItem.domicilioLegal.cp
                    ? this.editedItem.domicilioLegal.cp.toString()
                    : "",
                  location: this.editedItem.domicilioLegal.localidad
                    ? this.editedItem.domicilioLegal.localidad
                    : "",
                  partido: this.editedItem.domicilioLegal.partido
                    ? this.editedItem.domicilioLegal.partido
                    : "",
                  province: this.editedItem.domicilioLegal.provincia
                    ? this.editedItem.domicilioLegal.provincia.name
                    : "",
                  neighborhood: this.editedItem.domicilioLegal.barrio
                    ? this.editedItem.domicilioLegal.barrio.name
                    : "",
                  isActive: true,
                },
                addressTypeId: 2,
              });
            } else {
              this.formPerson.addresses.push({
                address: {
                  street: this.editedItem.domicilioLegal.calle
                    ? this.editedItem.domicilioLegal.calle
                    : "",
                  number: this.editedItem.domicilioLegal.numero
                    ? this.editedItem.domicilioLegal.numero.toString()
                    : "",

                  postCode: this.editedItem.domicilioLegal.cp
                    ? this.editedItem.domicilioLegal.cp.toString()
                    : "",
                  location: this.editedItem.domicilioLegal.localidad
                    ? this.editedItem.domicilioLegal.localidad
                    : "",
                  partido: this.editedItem.domicilioLegal.partido
                    ? this.editedItem.domicilioLegal.partido
                    : "",
                  province: this.editedItem.domicilioLegal.provincia
                    ? this.editedItem.domicilioLegal.provincia.name
                    : "",
                  neighborhood: this.editedItem.domicilioLegal.barrio
                    ? this.editedItem.domicilioLegal.barrio.name
                    : "",
                  isActive: true,
                },
                addressTypeId: 2,
              });
            }
          }

          if (this.editedItem.domicilioReal) {
            if (this.editedItem.id) {
              this.formPerson.addresses.push({
                id: this.editedItem.idPersDomReal,
                address: {
                  id: this.editedItem.idDomReal,
                  street: this.editedItem.domicilioReal.calle
                    ? this.editedItem.domicilioReal.calle
                    : "",
                  number: this.editedItem.domicilioReal.numero
                    ? this.editedItem.domicilioReal.numero.toString()
                    : "",

                  postCode: this.editedItem.domicilioReal.cp
                    ? this.editedItem.domicilioReal.cp.toString()
                    : "",
                  location: this.editedItem.domicilioReal.localidad
                    ? this.editedItem.domicilioReal.localidad
                    : "",
                  partido: this.editedItem.domicilioReal.partido
                    ? this.editedItem.domicilioReal.partido
                    : "",
                  province: this.editedItem.domicilioReal.provincia
                    ? this.editedItem.domicilioReal.provincia.name
                    : "",
                  neighborhood: this.editedItem.domicilioReal.barrio
                    ? this.editedItem.domicilioReal.barrio.name
                    : "",
                  isActive: true,
                },
                addressTypeId: 1,
              });
            } else {
              this.formPerson.addresses.push({
                address: {
                  street: this.editedItem.domicilioReal.calle
                    ? this.editedItem.domicilioReal.calle
                    : "",
                  number: this.editedItem.domicilioReal.numero
                    ? this.editedItem.domicilioReal.numero.toString()
                    : "",

                  postCode: this.editedItem.domicilioReal.cp
                    ? this.editedItem.domicilioReal.cp.toString()
                    : "",
                  location: this.editedItem.domicilioReal.localidad
                    ? this.editedItem.domicilioReal.localidad
                    : "",
                  partido: this.editedItem.domicilioReal.partido
                    ? this.editedItem.domicilioReal.partido
                    : "",
                  province: this.editedItem.domicilioReal.provincia
                    ? this.editedItem.domicilioReal.provincia.name
                    : "",
                  neighborhood: this.editedItem.domicilioReal.barrio
                    ? this.editedItem.domicilioReal.barrio.name
                    : "",
                  isActive: true,
                },
                addressTypeId: 1,
              });
            }
          }
          let tituloSwal = this.editedItem.id ? "Actualizar" : "Guardar";
          let tituloSwalOk = this.editedItem.id
            ? "actualizaron"
            : "registraron";
          Swal.fire({
            title: "<b>" + tituloSwal + " Datos del " + this.title + "?</b>",
            text: "Presione Aceptar para confirmar los datos del " + this.title,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              if (this.editedItem.id) {
                Swal.alertGetInfo("Actualizando Datos de la Persona");
                this.formPerson.id = this.editedItem.id;
                console.log(this.formPerson);
                this.$axiosApi
                  .putExternal(this.route, this.formPerson)
                  .then((r) => {
                    //console.log(r);
                    //console.log(r.data.data.id);
                    if (
                      typeof r !== "undefined" &&
                      r !== null &&
                      (r.status == 200 ||
                        r.status == 201 ||
                        r.succeeded == true)
                    ) {
                      this.editedItem.id = r.data.data.id;
                      //this.item = this.editedItem;
                      if (
                        r.data.data.personType == "2" &&
                        this.editedItem.apoderados > 0
                      ) {
                        this.savePersonRelationship(r.data.data.id);
                        this.editedItem.datos_apoderados =
                          this.$refs.apoderados.apoderados;
                      } else {
                        Swal.close();
                        Swal.fire({
                          title:
                            "Se " + tituloSwalOk + " los datos de la persona ",
                          icon: "success",
                          html: "Puede continuar cargando información",
                          showCloseButton: false,
                          showCancelButton: false,
                          focusConfirm: true,
                          confirmButtonColor: "#3085d6",
                          confirmButtonText: "Aceptar",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.$emit("save-persona", this.editedItem);
                            this.close();
                          }
                        });
                      }
                    } else {
                      Swal.close();
                      Swal.fire({
                        icon: "error",
                        title: "Registrar Persona",
                        text: "Ha ocurrido un error al intentar registrar los datos de la persona",
                      });

                      return;
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              } else {
                Swal.alertGetInfo("Guardando Datos de la Persona");
                this.$axiosApi
                  .postExternal(this.route, this.formPerson)
                  .then((r) => {
                    console.log(r);
                    console.log(this.editedItem);
                    if (
                      typeof r !== "undefined" &&
                      r !== null &&
                      (r.status == 200 ||
                        r.status == 201 ||
                        r.succeeded == true)
                    ) {
                      let addresses = r.data.data.addresses;
                      let emails = r.data.data.emails;
                      let identifiers = r.data.data.identifiers;
                      let phones = r.data.data.phones;
                      if (addresses) {
                        let idDomReal = addresses.filter(
                          (item) => item.addressTypeId == 1
                        );
                        //.map((item) => item["id"]);
                        let idDomLegal = addresses.filter(
                          (item) => item.addressTypeId == 2
                        );
                        //.map((item) => item["id"]);
                        if (idDomLegal) {
                          this.editedItem.idPersDomLegal = idDomLegal[0].id;
                          this.editedItem.idDomLegal = idDomLegal[0].address.id;
                        }
                        if (idDomReal) {
                          this.editedItem.idPersDomReal = idDomReal[0].id;
                          this.editedItem.idDomReal = idDomReal[0].address.id;
                        }
                      }
                      if (emails) {
                        this.editedItem.idMmail = emails[0].id;
                      }
                      if (identifiers) {
                        this.editedItem.idCuitCuil = identifiers[0].id;
                        this.editedItem.idDocumento = identifiers[1].id;
                      }
                      if (phones) {
                        this.editedItem.idPhone = phones[0].id;
                      }
                      this.editedItem.id = r.data.data.id;
                      //this.item = this.editedItem;
                      if (
                        r.data.data.personType == "2" &&
                        this.editedItem.apoderados > 0
                      ) {
                        this.savePersonRelationship(r.data.data.id);
                        this.editedItem.datos_apoderados =
                          this.$refs.apoderados.apoderados;
                      } else {
                        Swal.close();
                        Swal.fire({
                          title:
                            "Se " + tituloSwalOk + " los datos de la persona ",
                          icon: "success",
                          html: "Puede continuar cargando la información del trámite en la siguiente sección",
                          showCloseButton: false,
                          showCancelButton: false,
                          focusConfirm: true,
                          confirmButtonColor: "#3085d6",
                          confirmButtonText: "Aceptar",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.$emit("save-persona", this.editedItem);
                            this.close();
                          }
                        });
                      }
                    } else {
                      Swal.close();
                      Swal.fire({
                        icon: "error",
                        title: "Registrar Persona",
                        text: "Ha ocurrido un error al intentar registrar los datos de la persona",
                      });

                      return;
                    }
                  })
                  .catch((e) => {
                    Swal.close();
                    console.log(e);
                    this.snackbar = true;
                    this.text =
                      "Error al ingresar los datos. Error: " + e.message;
                    this.color = "error";
                  });
              }

              //this.$alertGetInfo("Registrando información del tramite");
            }
          });
        } else {
          Swal.alertError(
            "Error al registrar " + this.editedItem.businessName,
            this.editedItem.businessName +
              " posee " +
              this.editedItem.apoderados +
              " apoderados y hay " +
              this.cantApoderadosRegistrados +
              " registrados"
          );
        }
      }
    },

    validarApoderados() {
      if (this.editedItem.personType.id == 1) {
        return true;
      } else {
        this.cantApoderadosRegistrados = 0;

        for (let i = 0; i < this.$refs.apoderados.apoderados.length; i++) {
          if (this.$refs.apoderados.apoderados[i].documento > 0) {
            this.cantApoderadosRegistrados++;
          }
        }

        if (
          this.$refs.apoderados.apoderados.length ==
          this.cantApoderadosRegistrados
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    setDNI() {
      this.editedItem.documento = this.editedItem.cuit.substr(3, 8);
      let tipoDoc = this.identifiertypes.filter(
        (item) => item.name.toUpperCase() == "DNI"
      );
      //console.log(tipoDoc[0]);
      this.editedItem.tipo_documento = tipoDoc[0]
        ? tipoDoc[0]
        : { id: 4, name: "DNI" };
    },
    permitirSoloPersonaHumana() {
      if (this.cantTitulares > 1) {
        //Solo puede haber una persona Juridica por trámite
        //si es Soc Hecho debe haber 1 persona juridica y el resto fisicas
        if (this.tramite.tramite.sociedad_hecho == "S") {
          if (this.item.index == 0) {
            this.tipoPersonas = this.tipoPersonas.filter(
              (item) => item.id === 2
            );
            // this.editedItem.personType = { id: 1, name: "PERSONA HUMANA" };
          } else {
            this.tipoPersonas = this.tipoPersonas.filter(
              (item) => item.id === 1
            );
          }
        } else {
          // si el primer titular es juridica el resto son juridicos
          // si el primer titular es fisico el resto son fisicos
          // no pueden esta combinados salvo que sean sociedad de hecho

          if (
            this.item.index == 0 &&
            typeof this.titulares[1].personType.id == "undefined"
          ) {
            // si esta editando el primer titular y el segundo esta vacio le permito cambiar el tipo de persona
          } else {
            if (
              typeof this.titulares[0].personType != "undefined" &&
              this.titulares[0].personType.id == 2
            ) {
              this.tipoPersonas = this.tipoPersonas.filter(
                (item) => item.id === 2
              );
              this.editedItem.personType = { id: 2, name: "PERSONA JURÍDICA" };
              this.personaFisica = false;
            }

            if (
              typeof this.titulares[0].personType != "undefined" &&
              this.titulares[0].personType.id == 1
            ) {
              this.tipoPersonas = this.tipoPersonas.filter(
                (item) => item.id === 1
              );
              this.editedItem.personType = { id: 1, name: "FÍSICA" };
              this.personaFisica = true;
            }
          }
        }
      } else {
        this.editedItem.porcentaje = "100";
        if (this.personaFisica) {
          this.editedItem.lastName = this.editedItem.lastName
            ? this.editedItem.lastName
            : "";
        }
        console.log("this.editedItem.lastName");
        console.log(this.editedItem.lastName);
        this.tipoPersonas = this.$getPersonsTypes();
      }
    },
    formularioDisabled() {
      if (this.tramite.tramite.sociedad_hecho == "S") {
        if (this.editedItem.index == 0) {
          return false;
        } else {
          return Number(this.editedItem.index) % 2 == 0;
        }
      } else {
        return Number(this.editedItem.index) % 2 != 0;
      }
    },
    calcularPorcentajeRestante() {
      const documentoActual = this.editedItem.documento;
      const titularesExcluidos = this.titulares.filter(
        (titular) => titular.documento !== documentoActual
      ); // Excluyo el titular actual
      let sumaPorcentajes = titularesExcluidos.reduce(
        (acc, titular) => acc + (parseInt(titular.porcentaje) || 0),
        0
      );
      const porcentajeActual = parseInt(this.editedItem.porcentaje) || 0;
      sumaPorcentajes += porcentajeActual;
      //console.log(sumaPorcentajes);
      this.porcentajeRestante = 100 - sumaPorcentajes;

      if (this.porcentajeRestante < 0) {
        this.porcentajeError = "El porcentaje no puede ser mayor que 100";
      } else {
        this.porcentajeError = "";
      }
    },
    formatDate(date) {
      try {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      } catch (error) {
        console.log("formatDate Error");
      }
    },
    parseDate(date) {
      try {
        if (!date) return null;
        if (date.length !== 10) {
          this.editedItem.birthDate = this.formatDate(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          );
          return;
        }
        const [month, day, year] = date.split("/");
        return `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
      } catch (error) {
        console.log("parseDate Error");
      }
    },
    dateValido() {
      if (!this.editedItem.birthDate) {
        this.editedItem.birthDate = this.formatDate(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        );
      }
    },
    datePreSet() {
      if (this.editedItem.birthDate) {
        console.log(this.editedItem.birthDate);
        const [month, day, year] = this.editedItem.birthDate.split("/");
        this.date = `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
      }
    },
  },
  mounted() {
    console.log("Componente " + getTitle() + " creado");
    this.datePreSet();
  },
  watch: {
    date(val) {
      this.editedItem.birthDate = this.formatDate(this.date);
    },
    "editedItem.maritalStatusId": {
      handler(newVal, oldVal) {
        if (newVal && newVal.name) {
          if (["DIVORCIADO/A", "VIUDO/A"].includes(newVal.name)) {
          } else {
            this.editedItem.maritalStatusId.numeroNupcias = 0;
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.icon-size {
  width: 80px !important;
  height: 80px !important;
}
.bg-gris {
  background-color: #eee;
}
</style>
