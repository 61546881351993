<template>
	<v-card id="domicilio" outlined>
		<v-card-title
			:class="Number(indexPadre) == 0 ? 'primary text-white' : 'primary-2'"
			class="text-h3 text--accent-2 text-left"
		>
			<v-row wrap>
				<v-col
					cols="12"
					:xs="showCopy || showRegistrar ? 6 : 12"
					:md="showCopy || showRegistrar ? 6 : 12"
				>
					{{ formTitle }}
				</v-col>
				<v-col v-if="showCopy" cols="12" xs="6" md="6">
					<v-switch
						dark
						@click="setCopy()"
						label="Igual al Legal"
						color="white"
						class="mt-0"
						hide-details
						v-model="switchCopy"
					>
					</v-switch>
				</v-col>
				<v-col v-if="showRegistrar" cols="12" xs="6" md="6">
					<v-switch
						dark
						@click="setRegistrar()"
						label="Registrar"
						color="white"
						class="mt-0"
						hide-details
						v-model="switchRegistrar"
					>
					</v-switch>
				</v-col>
			</v-row>
		</v-card-title>

		<v-card-text>
			<v-container>
				<v-row>
					<v-col cols="12" md="6" sm="12" class="py-0">
						<v-text-field
							v-model="item.calle"
							label="Calle"
							:rules="switchRegistrar ? [$rulesRequerido, $rulesAlfaNum] : []"
							:disabled="!switchRegistrar"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2" sm="12" class="py-0">
						<v-text-field
							v-model="item.numero"
							label="Numero"
							:rules="
								switchRegistrar ? [$rulesRequerido, $rulesEnterosPositivos] : []
							"
							hint="Ingrese solo números"
							class="input-h1"
							maxLength="8"
							:disabled="!switchRegistrar"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2" sm="12" class="py-0">
						<v-text-field
							v-model="item.piso"
							label="Piso"
							:rules="switchRegistrar ? [$rulesEnterosPositivos] : []"
							hint="Ingrese solo números"
							class="input-h1"
							maxLength="8"
							:disabled="!switchRegistrar"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="2" sm="12" class="py-0">
						<v-text-field
							v-model="item.depto"
							label="Depto"
							:rules="switchRegistrar ? [$rulesAlfaNum] : []"
							:disabled="!switchRegistrar"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="6" sm="12" class="py-0">
						<v-autocomplete
							:items="provincias"
							item-value="name"
							item-text="name"
							label="Provincia"
							v-model="item.provincia"
							:rules="switchRegistrar ? [$rulesRequerido] : []"
							return-object
							:disabled="!switchRegistrar"
						></v-autocomplete
					></v-col>
					<v-col cols="12" md="6" sm="12" class="py-0">
						<v-text-field
							v-model="item.localidad"
							label="Localidad"
							:rules="switchRegistrar ? [$rulesRequerido, $rulesAlfaNum] : []"
							:disabled="!switchRegistrar"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="8" sm="12" class="py-0">
						<v-autocomplete
							:items="barrios"
							item-value="name"
							item-text="name"
							label="Barrio / Paraje"
							v-model="item.barrio"
							:rules="switchRegistrar ? [$rulesRequerido] : []"
							return-object
							:disabled="!switchRegistrar"
						></v-autocomplete
					></v-col>
					<v-col cols="12" md="4" sm="12" class="py-0">
						<v-text-field
							v-model="item.cp"
							label="Codigo Postal"
							:rules="
								switchRegistrar ? [$rulesRequerido, $rulesEnterosPositivos] : []
							"
							hint="Ingrese solo números"
							maxLength="4"
							:disabled="!switchRegistrar"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="12" sm="12" class="py-0">
						<v-text-field
							v-model="item.partido"
							label="Partido / Municipio / Depto"
							:rules="switchRegistrar ? [$rulesRequerido] : []"
							:disabled="!switchRegistrar"
						></v-text-field>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>
<style scoped>
.switch-center {
	display: flex;
	justify-content: center;
}
</style>
<script>
	function getTitle() {
		return "domicilios";
	}

	export default {
		name: "DomicilioForm",
		props: {
			item: { Object, default: null },
			title: { String, default: null },
			showCopy: { Boolean, default: false },
			itemCopy: { Object, default: null },
			index: { Number, default: null },
			indexPadre: { Number, default: null },
			showRegistrar: { Boolean, default: false },
		},
		data: (vm) => ({
			switchCopy: false,
			valid: true,
			autoGrow: true,
			rows: 1,
			route: "domicilios",
			menu: false,
			modal: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			editedIndex: -1,
			editedItem: {},
			defaultItem: {},
			provincias: [
				{ id: 1, name: "CAPITAL FEDERAL" },
				{ id: 2, name: "BUENOS AIRES" },
				{ id: 3, name: "SANTA FE" },
				{ id: 4, name: "CORDOBA" },
				{ id: 5, name: "MENDOZA" },
			],
			barrios: [
				{ id: 1, name: "CABA" },
				{ id: 2, name: "EZEIZA" },
				{ id: 3, name: "MONTE GRANDE" },
				{ id: 4, name: "RAMOS MEJIA" },
				{ id: 5, name: "SAN JUSTO" },
			],
			switchRegistrar: true,
		}),

		computed: {
			messages() {
				return this.showMessages ? ["Message"] : undefined;
			},
			formTitle() {
				return this.editedIndex === -1
					? this.title
						? this.title
						: getTitle()
					: this.title
					? this.title
					: getTitle();
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
			pagination: {
				handler() {
					this.initialize();
				},
				deep: true,
			},
		},

		created() {
			this.editedItem = this.item;
			//this.switchRegistrar = this.showRegistrar ? false:;
			//this.setDatosPrueba();
		},

		methods: {
			setDatosPrueba() {
				this.item.calle = "LAVALLE";
				this.item.numero = 1234;
				this.item.provincia = { id: 1, name: "CAPITAL FEDERAL" };
				this.item.localidad = "EZEIZA";
				this.item.barrio = { id: 1, name: "CABA" };
				this.item.cp = 1804;
				this.item.partido = "EZEIZA";
				//this.item.municipio = "EZEIZA";
			},
			setRegistrar() {
				if (!this.switchRegistrar) {
					this.item.calle = "";
					this.item.numero = "";
					this.item.piso = "";
					this.item.depto = "";
					this.item.provincia = "";
					this.item.localidad = "";
					this.item.barrio = "";
					this.item.cp = "";
					this.item.partido = "";

					this.$emit("resetItem");
				}
			},
			setCopy() {
				//console.log(this.switchCopy);
				this.$emit("copiar-domicilio", this.switchCopy);
				//this.item = this.itemCopy;
			},
			reset() {
				//this.$refs.personaForm.reset();
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
				this.resetValidation();
			},
			close() {
				//this.dialog = false;
				this.reset();
				this.$emit("send-message");
			},
			resetValidation() {
				this.$refs.personaForm.resetValidation();
			},

			save() {
				if (this.$refs.personaForm.validate()) {
					Swal.fire({
						title: "<b>Guardar Datos del " + this.title + "?</b>",
						icon: "question",
						showCloseButton: false,
						showCancelButton: true,
						focusConfirm: true,
						confirmButtonColor: "green",
						cancelButtonColor: "#d33",
						confirmButtonText: "Aceptar",
						cancelButtonText: "Cancelar",
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							//this.$alertGetInfo("Registrando información del tramite");
							this.$emit("save-persona", this.item);
							this.close();
						}
					});
				}
			},
		},
		mounted() {
			console.log("Componente " + this.title + " creado");
		},
	};
</script>
