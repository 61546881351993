<template>
	<v-row justify="center">
		<div>
			<v-row justify="center">
				<v-dialog v-model="dialogPersona" fullscreen persistent>
					<PersonaForm
						@send-message="close()"
						@save-persona="savePersona"
						:title="title"
						:item="persona"
						:key="keyFormPersona"
						:titulares="personas"
						:cantTitulares="items"
						:tramite="tramite"
					></PersonaForm>
				</v-dialog>
			</v-row>
		</div>
		<v-col cols="9">
			<v-data-table
				:headers="headers"
				:items="personas"
				class="elevation-1"
				:hide-default-footer="true"
				:key="keyTablePersonas"
				item-key="index"
				:height="heightGrillaTitulares"
				:items-per-page="1000"
			>
				<template v-slot:[`item.numero`]="{ item }">
					<table class="px-0 mx-0">
						<tr>
							<td class="pa-0 mx-0">
								<v-img
									class="icon-size"
									:src="item.index == 0 ? avatar : avatarCondomino"
								></v-img>
							</td>
							<td class="pa-0 mx-0 text-start">
								<v-chip
									prepend-icon="mdi-user"
									class="ml-1 d-flex justify-center"
									default
									style="width: 110px"
									:color="item.index == 0 ? 'primary' : 'primary-2'"
								>
									{{ item.funcion }}
								</v-chip>
							</td>
						</tr>
					</table>
				</template>
				<template v-slot:[`item.lastName`]="{ item }">
					{{
						item.lastName
							? item.lastName + ", " + item.firstName
							: item.businessName
					}}
				</template>

				<template v-slot:[`item.personType`]="{ item }">
					{{
						item.personType && Object.keys(item.personType).length > 0
							? Number(item.personType.id) == 1
								? "Física"
								: "Jurídica"
							: ""
					}}
				</template>
				<template v-slot:[`item.cuit`]="{ item }"> {{ item.cuit }}</template>
				<template v-slot:[`item.formulario_01`]="{ item }">
					{{ item.formulario_01 }}
				</template>
				<template v-slot:[`item.porcentaje`]="{ item }">
					{{ item.porcentaje > 0 ? item.porcentaje : "" }}
				</template>
				<template v-slot:[`item.titular_principal`]="{ item }">
					<div class="d-flex justify-center">
						<v-checkbox
							v-if="item.id"
							v-model="item.titular_principal"
							:disabled="!item.habilitarCheckbox || disabledCheck"
							@change="setTitularPrincipal($event, item)"
						></v-checkbox>
					</div>
				</template>

				<template v-slot:[`item.nombre`]="{ item }">
					{{ item.nombre }}
				</template>
				<template v-slot:[`item.tipo_persona`]="{ item }">
					{{
						item.tipo_persona
							? Number(item.tipo_persona.id) == 1
								? "Física"
								: "Jurídica"
							: ""
					}}
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-col
						cols="auto"
						:class="item.id ? 'd-flex justify-space-between' : 'justify-center'"
					>
						<v-btn
							:color="item.index == 0 ? 'primary' : 'primary-2'"
							fab
							small
							@click="showPersonaForm(item)"
						>
							<v-icon
								color="white"
								:title="
									(item.index == 0) != 0
										? 'Cargar o editar datos del titular'
										: 'Cargar o editar datos del condómino'
								"
								>mdi-account-edit
							</v-icon>
						</v-btn>
						<v-btn
							:color="'danger'"
							fab
							small
							@click="resetRow(item.index)"
							v-if="item.id"
						>
							<v-icon
								color="white"
								:title="
									item.index == 0
										? 'Limpiar datos del titular'
										: 'Limpiar datos del condómino'
								"
								>mdi-delete
							</v-icon>
						</v-btn>
					</v-col>
				</template>
			</v-data-table>
		</v-col>
	</v-row>
</template>

<style scoped>
.text-fields-row {
	display: flex;
}

.x-btn-grid {
	flex: unset;
	background-color: bisque;
	margin: 0 0px;
}

.back-condomino {
	background-color: whitesmoke;
}

.icon-center {
	margin: 4px 0px 0px 3px;
}

.icon-size {
	width: 32px !important;
	height: 32px !important;
}
</style>

<script>
	import PersonaForm from "../../personas/components/PersonaForm";

	export default {
		components: {
			PersonaForm,
		},
		props: {
			items: { Number, default: 0 },
			titulares: { Array, default: null },
			tramite: { Object, default: null },
		},
		data: (vm) => ({
			form: {},
			modal: false,
			dialogPersona: false,
			disabledCheck: false,
			title: "",
			persona: {},
			routePersons: "persons",
			keyTablePersonas: 0,
			keyFormPersona: 0,
			headers: [
				/*{ text: "Numero", filterable: true, value: "numero" },*/
				{ text: "Id", value: "index", align: " d-none" },
				{ text: "Propietario", value: "numero" },
				{ text: "N° Formulario", value: "formulario_01" },
				{ text: "Persona", value: "personType" },
				{ text: "Cuit / Cuil", value: "cuit" },
				{ text: "Nombre", value: "lastName" },
				{ text: "Porc. Tit.", value: "porcentaje" },
				{
					text: "Titular Principal",
					value: "titular_principal",
					align: "center",
					sortable: false,
				},
				{
					text: "Acciones",
					value: "actions",
					sortable: false,
					align: "center",
					class: "acciones-width",
				},
			],

			avatar: "/img/user_primary.png",
			avatarCondomino: "/img/user_primary_2.png",
			personas: [],
			itemKey: "",
			baseUrl: "",
			heightGrillaTitulares: 0,
		}),
		methods: {
			savePersona(value) {
				//console.log("guardando data d personas");
				if (value) {
					let i = Number(value.index);
					this.personas[i] = value;
					this.keyTablePersonas = this.keyTablePersonas + 1;
          this.updateTitularPrincipal();
				}
			},
			close() {
				this.dialogPersona = false;
			},
			showPersonaForm(item) {
				// si es una sociedad de hecho

				if (this.tramite.tramite.sociedad_hecho == "S") {
					if (item.index == 0) {
						this.title = item.funcion;
						this.persona = item;
						//this.persona.personType = 2;
						this.keyFormPersona = this.keyFormPersona + 1;
						this.dialogPersona = true;
					} else {
						if (item.index > 0) {
							this.title = item.funcion;
							item.formulario_01 =
								item.numero % 2 == 0
									? this.personas[item.index - 1].formulario_01
									: this.personas[item.index].formulario_01;
							this.persona = item;
							this.keyFormPersona = this.keyFormPersona + 1;
							this.dialogPersona = true;
						} else {
							this.title = item.funcion;

							this.persona = item;
							this.keyFormPersona = this.keyFormPersona + 1;
							this.dialogPersona = true;
						}
					}
				} else {
					if (item.index == 0) {
						this.title = item.funcion;
						this.persona = item;
						this.keyFormPersona = this.keyFormPersona + 1;
						this.dialogPersona = true;
					} else {
						this.title = item.funcion;
						/*item.formulario_01 = this.personas[item.index - 1].formulario_01;*/
						item.formulario_01 =
							item.numero % 2 == 0
								? this.personas[item.index - 1].formulario_01
								: this.personas[item.index].formulario_01;
						this.persona = item;
						this.keyFormPersona = this.keyFormPersona + 1;
						this.dialogPersona = true;
					}
				}
			},
			add(items) {
				let numero = 0;
				let funcion = "";

				for (let i = 0; i < Number(items); i++) {
					if (i == 0) {
						numero = 1;
						funcion = "Titular N° " + numero;
					} else {
						//if (i % 2 == 0) {
						numero++;
						funcion = "Condómino N° " + numero;
						/*} else {
							                              funcion = "Condómino N° " + numero;
							                            }*/
					}
					let tipoPersona = "";
					if (this.tramite.tramite.sociedad_hecho == "S" && i == 0) {
						tipoPersona = 2;
					}
					this.personas.push({
						id: "",
						tipo_persona: tipoPersona,
						tipo_documento: "",
						cuit: "",
						pais_dni: "",
						nacionalidad: "",
						porcentaje: 0,
						titular_principal: false,
						ocupacion: "",
						telefono: "",
						mail: "",
						fecha_nacimiento: "",
						lugar_nacimiento: "",
						estado_civil: "",
						formulario_01: "",
						documento: "",
						sexo: "",
						otorgada_por: "",
						numero_inscripcion: "",
						fecha_inscripcion: "",
						apoderados: "",
						domicilioLegal: {},
						domicilioReal: {},
						numero: numero,
						index: Number(i),
						funcion: funcion,
						activity: "",
						lastName: "",
						firstName: "",
					});
				}
				//console.log(this.personas);
			},

			remove(index) {
				this.personas.splice(index, 1);
			},
			resetRow(index) {
				Swal.fire(
					Swal.objQuestion(
						"<b>Limpiar Datos de Persona</b>",
						"¿Desea limpiar los datos de la persona?"
					)
				).then((result) => {
					if (result.isConfirmed && this.personas[index]) {
						//Limpio los datos de la persona
						this.personas[index].id = "";
						this.personas[index].tipo_documento = "";
						this.personas[index].activity = "";
						this.personas[index].lastName = "";
						this.personas[index].firstName = "";
						this.personas[index].businessName = "";
						this.personas[index].birthPlace = "";
						this.personas[index].birthDate = "";
						this.personas[index].cuit = "";
						this.personas[index].pais_dni = "";
						this.personas[index].nacionalidad = "";
						this.personas[index].ocupacion = "";
						this.personas[index].telefono = "";
						this.personas[index].mail = "";
						this.personas[index].fecha_nacimiento = "";
						this.personas[index].lugar_nacimiento = "";
						this.personas[index].estado_civil = "";
						this.personas[index].formulario_01 = "";
						this.personas[index].documento = "";
						this.personas[index].sexo = "";
						this.personas[index].actividad = "";
						this.personas[index].otorgada_por = "";
						this.personas[index].numero_inscripcion = "";
						this.personas[index].fecha_inscripcion = "";
						this.personas[index].apoderados = "";
						this.personas[index].porcentaje = "";
						this.personas[index].titular_principal = false;
						this.personas[index].personType = {};
						this.personas[index].meritalStatusId = {};
						this.personas[index].genderId = {};
						this.personas[index].domicilioLegal = {};
						this.personas[index].domicilioReal = {};
						this.personas[index].tipo_persona = "";

            this.updateTitularPrincipal();

						Swal.close();
						Swal.fire(
							Swal.objSuccess(
								"Campos vaciados",
								"Se han vaciado los campos de la persona"
							)
						);
					}
				});
			},
			setTitularPrincipal(event, titularSeleccionado) {
				// Desmarca todos los titulares excepto el seleccionado
				this.personas.forEach((persona) => {
					if (persona !== titularSeleccionado) {
						persona.titular_principal = false;
					} else if (event) {
						persona.titular_principal = true;
					} else {
						persona.titular_principal = false;
					}
				});
			},
			updateTitularPrincipal() {
				// Deshabilito todos los checkboxes
				this.personas.forEach((p) => {
					p.habilitarCheckbox = false;
					p.titular_principal = false;
				});

				this.disabledCheck = false;

				// Suma total de los porcentajes
				const sumaPorcentajes = this.personas.reduce((total, persona) => {
					const auxPorcentaje = persona.porcentaje ? persona.porcentaje : 0; //viene undefined
					const porcentajeNumerico = parseFloat(auxPorcentaje);
					return total + porcentajeNumerico;
				}, 0);

				if (sumaPorcentajes !== 100) {
					// Si la suma no es igual a 100, desactivar los checks
					this.personas.forEach((persona) => {
						persona.titular_principal = false;
					});
					this.disabledCheck = true;
				} else {
					// Consulto el mayor porcentaje
					const mayorPorcentaje = Math.max(
						...this.personas.map((p) => parseFloat(p.porcentaje) || 0)
					);

					// Filtro las personas con ese mayor porcentaje
					const personasConMayorPorcentaje = this.personas.filter(
						(p) => parseFloat(p.porcentaje) === mayorPorcentaje
					);

					// Habilito los checkboxes de las personas con mayor porcentaje
					this.personas.forEach((p) => {
						const porcentajePersona = parseFloat(p.porcentaje) || 0;
						p.habilitarCheckbox = porcentajePersona === mayorPorcentaje;
					});

					// Si solo hay una persona con el mayor porcentaje, se la marca como titular principal y se deshabilitan los checks
					if (personasConMayorPorcentaje.length === 1) {
						this.setTitularPrincipal(true, personasConMayorPorcentaje[0]);
						this.disabledCheck = true;
					}
				}
			},
		},
		created() {},

		mounted() {
			console.log("Componente TitularesRow creado");

			if (this.items > 0) {
				this.personas = this.titulares;
				this.heightGrillaTitulares = this.items * 70 + 50;
				if (this.heightGrillaTitulares > 400) {
					this.heightGrillaTitulares = 400;
				}
				//console.log("this.heightGrillaTitulares");
				//console.log(this.heightGrillaTitulares);
			} else if (this.items) {
				this.add(this.items);
			}
		},
	};
</script>
