import { render, staticRenderFns } from "./DomicilioForm.vue?vue&type=template&id=5674acf8&scoped=true"
import script from "./DomicilioForm.vue?vue&type=script&lang=js"
export * from "./DomicilioForm.vue?vue&type=script&lang=js"
import style0 from "./DomicilioForm.vue?vue&type=style&index=0&id=5674acf8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5674acf8",
  null
  
)

export default component.exports