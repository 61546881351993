<template>
  <div id="datos-titulares">
    <v-card-text style="background: #eee; overflow-y: scroll">
      <v-row class="mt-2">
        <v-col cols="12" md="12" sm="12">
          <base-material-card color="primary" class="px-5 py-3">
            <template v-slot:heading>
              <div class="text-h5 font-weight-light my-2">
                Datos de los Titulares
              </div>
            </template>

            <v-card-text style="">
              <div
                class="text-h4 font-weight-light mx-2 my-2 red--text text-right"
              >
                <p class="blue--text font-weight-light mx-2 text-right">
                  <b>
                    ATENCIÓN: Los datos se guardarán al avanzar a la siguiente
                    sección</b
                  >
                </p>
              </div>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <titularesRow
                    ref="titulares"
                    :items="Number(titulares)"
                    :titulares="datosTitulares"
                    :tramite="tramite"
                  ></titularesRow>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="8" md="8" sm="8">
                  <v-text-field
                    v-model="form.autorizados"
                    label="Solicitar cédula de autorizado:"
                    :rules="[$rulesEnterosPositivosOCero]"
                    maxLength="2"
                    hint="Ingrese la cantidad de cédulas a solicitar"
                    @keyup="setAutorizados()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="8" md="8" sm="8" class="py-0 pb-4">
                  <v-card id="autorizados" outlined v-show="showAutorizados">
                    <v-card-title class="text-h3 text-left primary text-white">
                      <v-row wrap>
                        <v-col cols="12" :xs="12"> Autorizados </v-col>
                      </v-row>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-card-text class="px-0">
                            <AutorizadosRow
                              :key="keyAutorizados"
                              ref="autorizados"
                              :items="Number(form.autorizados)"
                              :datosAutorizados="arrayAutorizados"
                            ></AutorizadosRow>
                          </v-card-text>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right" cols="12" md="12" sm="12">
                  <v-btn color="red" @click="$exit(idTramite)">Salir</v-btn>
                  <v-btn color="primary-2" @click="back()">Atras</v-btn>
                  <v-btn color="primary" @click="next()">Siguiente</v-btn>
                </v-col>
              </v-row>
              <br />
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import titularesRow from "../component/TitularesRowComponent";
import AutorizadosRow from "../component/AutorizadosRowComponent";

export default {
  name: "DatosTitulares",
  props: {
    datosTitulares: { Array, default: null },
    titulares: { Number, default: null },
    cedulas: { Number, default: null },
    datosAutorizados: { Array, default: null },
    tramite: { Object, default: null },
    idTramite: { Number, default: null },
  },
  components: {
    titularesRow,
    AutorizadosRow,
  },
  data: () => ({
    showAutorizados: false,
    keyAutorizados: 0,
    form: {},
    arrayAutorizados: [],
  }),
  methods: {
    back() {
      this.$emit("send-message", "1");
    },
    setAutorizados() {
      if (this.form.autorizados > 0) {
        if (typeof this.autorizados == "undefined") {
          this.showAutorizados = true;
          this.keyAutorizados = this.keyAutorizados + 1;
          this.autorizados = this.form.autorizados;
          return;
        }
        if (Number(this.form.autorizados) >= Number(this.autorizados)) {
          if (typeof this.$refs.autorizados !== "undefined") {
            if (typeof this.$refs.autorizados.autorizados !== "undefined") {
              this.arrayAutorizados = this.$refs.autorizados.autorizados;
            }
          }
          this.showAutorizados = true;
          this.keyAutorizados = this.keyAutorizados + 1;
          this.autorizados = this.form.autorizados;
        } else {
          let noticeAlert =
            "<b>Atención!</b>. Si reduce la cantidad de autorizados <b>(de " +
            Number(this.autorizados) +
            " a " +
            Number(this.form.autorizados) +
            ")</b>, se perderán algunos datos de los Autorizados. Desea modificar la cantidad de cédulas solicitadas?";

          Swal.fire(
            Swal.objQuestion(
              "<b>Solicitar Cédulas de Autorizados</b>",
              noticeAlert
            )
          ).then((result) => {
            if (result.isConfirmed) {
              let posFin = Number(this.autorizados) - 1;
              //this.$refs.autorizados.autorizados.splice(posFin, this.form.autorizados)
              this.arrayAutorizados = this.$refs.autorizados.autorizados;
              this.arrayAutorizados.splice(posFin, this.form.autorizados);
              this.showAutorizados = true;
              this.keyAutorizados = this.keyAutorizados + 1;
              this.autorizados = this.form.autorizados;
            } else {
              this.form.autorizados = this.autorizados;
            }
          });
          /*
          this.arrayAutorizados = [];
          this.showAutorizados = true;
          this.keyAutorizados = this.keyAutorizados + 1;
          this.autorizados = this.form.autorizados;*/
        } /*
        this.showAutorizados = true;
        this.keyAutorizados = this.keyAutorizados + 1;
        this.autorizados = this.form.autorizados;*/
        /*
        console.log(this.form.autorizados);
        console.log(this.autorizados);
        console.log(this.$refs.autorizados.autorizados);
        */

        //this.$refs.autorizados.autorizados = arrayAutorizados;
      } else {
        this.arrayAutorizados = [];
        this.autorizados = this.form.autorizados;
        this.keyAutorizados = this.keyAutorizados + 1;
        this.showAutorizados = false;
      }
    },
    validateArrayPersonas() {
      return new Promise((resolve) => {
        let arrayPersonas = this.$refs.titulares.personas;
        let i = 0;
        for (i; i < arrayPersonas.length; i++) {
          //console.log(arrayPersonas[i]);

          if (arrayPersonas[i].id == "") {
            resolve(false);
          }
        }
        resolve(true);
      });
    },
    validateAutorizados() {
      return new Promise((resolve) => {
        let arrayPersonas = this.$refs.autorizados.autorizados;
        let i = 0;
        for (i; i < arrayPersonas.length; i++) {
          console.log(arrayPersonas[i]);

          if (
            arrayPersonas[i].tipo_documento_id == "" ||
            arrayPersonas[i].documento == "" ||
            arrayPersonas[i].apellidos == "" ||
            arrayPersonas[i].nombres == ""
          ) {
            resolve(false);
          }
        }
        resolve(true);
      });
    },
    next() {
      let formData = {};
      formData.titulares = this.$refs.titulares.personas;
      if (this.form.autorizados > 0) {
        if (this.$refs.autorizados.$refs.autorizadosForm.validate()) {
          formData.cedulas = this.form.autorizados;
          formData.autorizados = this.$refs.autorizados.autorizados;
          //console.log(this.$refs.autorizados.autorizados);
          this.$emit("send-message", "3", "2", formData);
        } else {
          Swal.alertError(
            "Atención",
            "Si solicita cédula de autorizados, debe cargar todos los datos"
          );
          return;
        }

        /*this.validateAutorizados().then((result) => {
          if (result === true) {
            formData.cedulas = this.form.autorizados;
            formData.autorizados = this.$refs.autorizados.autorizados;
            //console.log(this.$refs.autorizados.autorizados);
            this.$emit("send-message", "3", "2", formData);
          } else {
            Swal.alertError(
              "Atención",
              "Si solicita cédula de autorizados, debe cargar todos los datos"
            );
            return;
          }
        });*/
      } else {
        formData.cedulas = null;
        formData.autorizados = null;
        //console.log(this.$refs.autorizados.autorizados);
        this.$emit("send-message", "3", "2", formData);
      }
    },
    /*async next() {
										        if (this.$refs.titulares.personas) {
										          const valid = await this.validateArrayPersonas();
										          console.log(valid);
										          if (valid == true) {
										            this.$emit("send-message", "3", "2", this.$refs.titulares.personas);
										          }
										        }
										      },*/
  },
  mounted() {
    //console.log(this.datosTitulares);
    if (this.cedulas) {
      this.form.autorizados = Number(this.cedulas);
      this.keyAutorizados = this.keyAutorizados + 1;
      this.showAutorizados = true;
      this.autorizados = this.form.autorizados;
    }
  },
  created() {
    if (this.datosAutorizados) {
      this.arrayAutorizados = this.datosAutorizados;
    }
  },
};
</script>

<style></style>
